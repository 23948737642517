
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from  'react-lib/apis/services/BaseService'
import config from '../../config/config'
import PatientAppointmentSerializer from '../types/PatientAppointmentSerializer_apps_QUE'


const HOST = `${config.API_HOST}`
/* ['generics.ListCreateAPIView'] */
/* params: 'hn', 'patient_id', 'division_id', 'reschedule_only', 'exclude_cancel', 'reconfirm_only', 'waiting_list', 'waiting_list_id', 'provider_id', 'requested_provider_id', 'type', 'appointment_related_type', 'status', 'waiting_list_status', 'estimate_start_dt', 'estimate_end_dt', 'start_datetime', 'end_datetime', 'start_time', 'end_time', 'start_date', 'end_date' */
/* data: 'encounter_id', 'is_or_type' */
const PatientAppointmentView : 
{
  list:     ({params, apiToken, extra}:
             { 
                params?: any,
                apiToken?: any,
                extra?: any,
            }) => any,
  create:   ({params, data, apiToken, extra}:
             { 
                params?: any,
                data?: PatientAppointmentSerializer,
                apiToken?: any,
                extra?: any
            }) => any,
} = 
{
  list: async ({params, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.get(`${HOST}/apis/QUE/patient-appointment/`,
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },

  create: async ({params, data, apiToken, extra}) => {
    const base = new BaseService()
    if (apiToken)
      base.client.defaults.headers["Authorization"] = "Token " + apiToken;
    if (extra?.division)
      base.client.defaults.headers["division"] = extra.division;
    if (extra?.device)
      base.client.defaults.headers["device"] = extra.device;
    const result = await to(base.client.post(`${HOST}/apis/QUE/patient-appointment/`,
      { ...data
      },
      { params: params ? { ...params } : {},
         ...extra
      }
    )
    .then(base.handleResponse)
    .catch(base.throwErrorMessage))
    return [result[1], result[0], result[2]]
  },
}

export default PatientAppointmentView

