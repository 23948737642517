import axios from 'axios';
import querystring from "querystring";
import settings from './settings';
import Cookies from 'js-cookie';
import { HOST } from 'react-lib/configs/apis';


class LineLoginError extends Error {
  constructor(message, response) {
    super();
    this.message = message;
    this.response = response
  }
}


export default class LineLoginManager {

  constructor() {
    this.client_id = settings.CHANNEL_ID;
    this.client_secret = settings.CHANNEL_SECRET;
    this.auth = null;
  }

  /**
   * Generate random uuid version 4
   * @returns {string}
   */
  uuidv4 = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }

  /**
   * Get CSRF Token which act as state for LINE OAuth.
   * This will create token and store it to cookie.
   * If a token is already exist in cookie,
   * return a value from cookie
   * @returns {string}
   */
  getCSRFState = () => {
    let token = Cookies.get('x-csrftoken');
    if (!token) {
      token = this.uuidv4();
      Cookies.set('x-csrftoken', token);
    }
    return token;
  }

  /**
   * Check that given token equal to CSRF token stored in cookie
   * @param token {string}
   * @returns {boolean}
   */
  verifyCSRFState = (token) => {
    return token === this.getCSRFState();
  }

  /**
   * Redirect to LINE Login page
   */
  openLogin = async () => {
    let payload = {
      response_type: 'code',
      client_id: this.client_id,
      client_secret: this.client_secret,
      redirect_uri: `${window.location.origin}/callback`,
      state: this.getCSRFState(),
      scope: 'openid profile email',
      max_age: 60,
    }
    let param = querystring.stringify(payload);
    if (navigator && navigator.userAgent) {
      let userAgent = navigator.userAgent
      if (userAgent.includes('VivoBrowser')) {
        alert(' Browser ไม่รองรับ กรุณาติดตั้ง Chrome Browser ')
        window.location.href = `https://play.google.com/store/apps/details?id=com.android.chrome`
      } else {
        window.location.href = `https://access.line.me/oauth2/v2.1/authorize?${param.toString()}`
      }
    }  else {
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?${param.toString()}`
    }
    
  }

  /**
   * get api token should be called after openLogin and before other function.
   * {
   *    "access_token":"<token>",
   *    "token_type":"Bearer",
   *    "refresh_token":"<token>",
   *    "expires_in": 2592000,
   *    "scope":"openid profile",
   *    "id_token":"<token>"
   * }
   * https://developers.line.biz/en/docs/line-login/integrate-line-login/#get-access-token
   * @param code {string} code get from query parameter after callback from LINE
   * @returns {Promise<*>}
   */
  getToken = async (code) => {
    const data = querystring.stringify({
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: `${window.location.origin}/callback`,
      client_id: settings.CHANNEL_ID,
      client_secret: settings.CHANNEL_SECRET,
    });
    try {
      let ret = await axios.post(
        'https://api.line.me/oauth2/v2.1/token',
        data,
        {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        }
      );
      this.auth = ret.data
      Cookies.set('line-access-token', ret.data.access_token);
    } catch (e) {
      console.error(e.response);
      throw new LineLoginError(e.response.data.error_description, e.response)
    }
    return this.auth.token
  }

  /**
   * return
   * {
   *   "userId": "<str>",
   *   "displayName": "<str>>",
   *   "pictureUrl": "<url>",
   *   "statusMessage": "<str>"
   * }
   * https://developers.line.biz/en/reference/social-api/#get-user-profile
   * @returns {Promise<*>}
   */
  getProfile = async () => {
    if (this.auth === null) {
      throw new LineLoginError("you have to call getToken() first", null);
    }
    try {
      let ret = await axios.get("https://api.line.me/v2/profile", {
        headers: { Authorization: `${this.auth.token_type} ${this.auth.access_token}` }
      })
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw new LineLoginError(e.response.data.error_description, e.response);
    }
  }

  /**
   * return
   * {
   *   "iss":"https://access.line.me",
   *   "sub":"Uab89916b834ffe1135c551dbc5749190",
   *   "aud":"<same as Channel ID in setting>",
   *   "exp":1585818057,
   *   "iat":1585814457,
   *   "amr":["linesso"],
   *   "name":"<display name>",
   *   "picture":"<url>",
   *   "email":"<email>"
   * }
   * https://developers.line.biz/en/docs/line-login/integrate-line-login/#verify-id-token
   * @param userId use userId from getProfile
   * @returns {Promise<*>}
   */
  getVerifyIDToken = async (userId) => {
    if (this.auth === null) {
      throw new LineLoginError("you have to call getToken() first", null);
    }
    if (!userId) {
      throw new LineLoginError("userId must be given", null);
    }
    try {
      let payload = {
        id_token: this.auth.id_token,
        client_id: this.client_id,
        user_id: userId,
      };
      let data = querystring.stringify(payload);
      let ret = await axios.post(
        'https://api.line.me/oauth2/v2.1/verify',
        data,
        {
          headers: { 'content-type': 'application/x-www-form-urlencoded'}
        });
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw new LineLoginError(e.response.data.error_description, e.response);
    }
  }

  /**
   * return
   * {
   *    "token": "<PentaCenter API Token>",
   *    "profile": {
   *        "lastname": "VC",
   *        "email":"napat@thevcgroup.com",
   *        "userId": 43,
   *        "image":"https://profile.line-scdn.net/0hvErVle9KKWpPLwF9GShWPXNqJwc4AS8iN01gCmovdl1nHj1pIxszWzovd1wwGD01dU80DWorclNh/large",
   *        "fullname":"Napat",
   *        "username":"napat-thevcgroup.com"
   *    }
   * }
   * @param app
   * @param email
   * @returns {Promise<*>}
   */
  getTokenFromAccount = async (app, email, subscription, device_id) => {
    const server = "https://penta.center/users/api/auth/line/"
    // const server = 'https://accounts.thevcgroup.com/apis/register_by_token/line/'
    // const server = 'http://127.0.0.1:8000/apis/register_by_token/line/'
    if (this.auth === null) {
      throw new LineLoginError("you have to call getToken() first", null);
    }

    const params: Record<string, any> = {
      access_token: this.auth.access_token,
      id_token: this.auth.id_token,
      app: app,
      device_token: JSON.stringify(subscription),
      unique_id: device_id,
      device_id: device_id,
      device_type: "webpush"
    };

    // const query = querystring.stringify(params)
    const data = params;
    if (email) {
      // query['email'] = email
      data["email"] = email;
    }
    try {
      // let ret = await axios.get(`${server}?${query}`);
      const ret = await axios.post(`${server}`, data);
      return ret.data;
    } catch (e) {
      console.error(e.response);
      throw new LineLoginError(e.response.data)
    }
  }


  handleLogout = async () => {
    let data = querystring.stringify({
      access_token: Cookies.get("line-access-token"),
      client_id: this.client_id,
      client_secret: this.client_secret,
    })
    let res = await axios.post(
      'https://api.line.me/oauth2/v2.1/revoke',
      data,
      {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
      }
    ); 
    Cookies.remove("line-access-token")
    console.log({res})
  }
}
