import React, { createRef } from "react";
import { withRouter, Route, Switch, useLocation } from "react-router-dom";
import { vcMessenger } from "../react-lib/compat/vc-websocket";
// CSS
import { InputProps } from "semantic-ui-react";

// Library
import Cookies from "js-cookie";
import Fingerprint2 from "fingerprintjs2";
import SlideRoutes from "react-slide-routes";
import {
  IntlProvider,
  FormattedMessage,
  createIntlCache,
  createIntl,
} from "react-intl";
import * as serviceWorker from "../serviceWorker";

// IsHealth
import {
  askUserPermission,
  createNotificationSubscription,
} from "../react-lib/apps/IsHealth/Common/push-notifications";
// import { flattenMessages } from "../react-lib/localization/IsHealth/util";
// import messages from "../react-lib/localization/IsHealth/messages";
import thMessage from "../react-lib/localization/IsHealth/th.json";
import enMessage from "../react-lib/localization/IsHealth/en.json";

// Framwords
import SetProp from "../react-lib/frameworks/SetProp";

// Controller
import MobTransformController from "./MobTransformController";

// Interface
import * as MobTransformI from "./MobTransformInterface";

// UI
import TFLogin from "../react-lib/apps/IsHealth/Transform/TFLogin";
import TFRegister from "../react-lib/apps/IsHealth/Transform/TFRegister";
import TFSignInEmail from "../react-lib/apps/IsHealth/Transform/TFSignInEmail";
import TFVerifyIdentity from "../react-lib/apps/IsHealth/Transform/TFVerifyIdentity";
import TFForgetPassword from "../react-lib/apps/IsHealth/Transform/TFForgetPassword";
import TFBottomNavigation from "../react-lib/apps/IsHealth/Transform/TFBottomNavigation";
import TFChatList from "../react-lib/apps/IsHealth/Transform/TFChatList";
import TFHead from "../react-lib/apps/IsHealth/Transform/TFHead";
import TFEditProfile from "../react-lib/apps/IsHealth/Transform/TFEditProfile";
import TFEditHN from "../react-lib/apps/IsHealth/Transform/TFEditHN";
import ConfirmShipping from "./ConfirmShipping";
import ShippingList from "./ShippingList";

import { ChatList } from "./Chat";
import ChatBox from "../react-lib/apps/IsHealth/Chat/ChatBox";
import { DiagRule, DiagRuleSearch } from "./DiagRule";
import DiagForm from "./DiagForm";
import OfficialAccount from "./OfficialAccount";
import ProfileWrap from "./ProfileWrap";
import Profile from "./Profile";
import PatientSelectAppointment from "../react-lib/apps/QUE/PatientSelectAppointment";
import MakeAppointment from "../patient-lib/MakeAppointment";
import TimePatient from "../react-lib/apps/QUE/TimePatient";
import PatientAppointmentPatient from "../react-lib/apps/QUE/PatientAppointmentPatient";
import PatientPayment from "./PatientPayment";
import PatientPaymentDetail from "./PatientPaymentDetail";
import EditAddress from "../Address/EditAddress";
import SelectAddress from "../Address/SelectAddress";
import DrugAddressConfirm from "../Address/DrugAddressConfirm";
import VCAppointmentView from "../react-lib/apps/IsHealth/Vaccine/VCAppointmentView";
import VCAppointmentSlot from "../react-lib/apps/IsHealth/Vaccine/VCAppointmentSlot";
import PatientSelectAppointmentIH from "../react-lib/apps/QUE/PatientSelectAppointmentIH";
import PatientCreateAppointmentIH from "../react-lib/apps/QUE/PatientCreateAppointmentIH";
import PatientEditAppointmentIH from "../react-lib/apps/QUE/PatientEditAppointmentIH";

import ModConfirm from "../react-lib/apps/common/ModConfirm";
import { SettingPopup } from "./MainPatient";

import CONFIG from "../config/config";

// Style
import "../react-lib/apps/IsHealth/Transform/Transform.scss";
// import config from "../config/config";

import * as MainVideoCall from "../MainVideoCall";

declare global {
  var iosNative: any;
  var MobNative: any;
  var FB: any;
}

const THEME = {
  DEFAULT: "",
  PENTA: "penta",
  TU: "tu",
  EGAT: "egat",
  PENGUIN: "penguin",
};

const URL_PREFIX = {
  PENGUIN: "penguin",
};

const PROJECT_NAME = {
  M_B_PLUS_TELEMED: "My B+ Telemed",
  TU: "TU",
  EGAT: "EGAT",
};

const LANGUAGE: {
  TH: "th-TH";
  EN_US: "en-US";
} = {
  TH: "th-TH",
  EN_US: "en-US",
};

const TUH_TRANSFORM = "/tuh-transform";

class MobTransform extends React.Component<any, MobTransformI.State> {
  controller: MobTransformController;
  chatListRef: any;
  chatBoxRef: any;
  lastReadMessage: any;
  selectAddressRef: any;
  drugAddressConfirmRef: any;
  currentLocation: any;
  unlisten: any;

  constructor(props: any) {
    super(props);
    this.state = {
      ...MobTransformI.StateInitial,
    };
    this.controller = new MobTransformController(
      () => {
        return this.state;
      },
      (state: MobTransformI.State, callback: any) => {
        this.setState(state, callback);
      },
      window
    );
    // @ts-ignore
    globalThis.setState = this.setState.bind(this);
    this.controller.setProp = SetProp(this, "");
    this.controller.cookies = Cookies;

    // Ref
    this.chatListRef = createRef();
    this.chatBoxRef = React.createRef();
    this.currentLocation = createRef();
    this.selectAddressRef = createRef();
    this.drugAddressConfirmRef = createRef();
    this.lastReadMessage = null;

    vcMessenger.onMessage((message: any) => {
      console.log({ message });
      if (message["data_message_type"] === "MESSAGE") {
        this.updateUnReadMessage(message);
        if (this.chatListRef) {
          this.loadChannelList();
        }
        if (this.chatBoxRef && this.chatBoxRef.receivedMessage) {
          this.chatBoxRef.receivedMessage({
            chatChannelId: parseInt(message["channel_id"]),
          });
        }
      } else if (message["data_message_type"] === "MESSAGE_READ") {
        if (this.lastReadMessage === message.message_id) {
          return;
        }
        this.lastReadMessage = message.message_id;
        if (this.chatBoxRef && this.chatBoxRef.receivedMessageRead) {
          this.chatBoxRef.receivedMessageRead({
            chatChannelId: parseInt(message["channel_id"]),
            messageId: parseInt(message["message_id"]),
          });
        }
      } else if (message["event"] === "RECLASSIFY") {
        if (this.chatBoxRef && this.chatBoxRef.loadPatientEncounter) {
          this.chatBoxRef.loadPatientEncounter();
        }
      }
    });
  }

  componentDidMount = () => {
    this.controller.handleEvent({ message: "DidMount", params: {} });
    let currentURL = window.location.href;
    if (CONFIG.COMPANY === "BDMS") {
      this.setState({
        theme: THEME.PENGUIN,
        projectName: PROJECT_NAME.M_B_PLUS_TELEMED,
      });
      document.documentElement.setAttribute("data-theme", THEME.PENGUIN);
      Cookies.set("theme", THEME.PENGUIN, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.M_B_PLUS_TELEMED, {
        path: "/",
      });
    } else if (CONFIG.COMPANY === "TU") {
      document.documentElement.setAttribute("data-theme", THEME.TU);
      Cookies.set("theme", THEME.TU, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.TU, {
        path: "/",
      });
    } else if (CONFIG.COMPANY === "EGAT") {
      document.documentElement.setAttribute("data-theme", THEME.EGAT);
      Cookies.set("theme", THEME.EGAT, { path: "/" });
      Cookies.set("project_name", PROJECT_NAME.EGAT, {
        path: "/",
      });
    } else {
      this.setState({ theme: THEME.PENTA });
      document.documentElement.setAttribute("data-theme", THEME.PENTA);
      Cookies.set("theme", THEME.PENTA, { path: "/" });
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
    if (prefersDarkScheme.matches) {
      // set dark-theme
      document.documentElement.setAttribute("color-scheme", "dark");
    } else {
      // remove dark-theme
      document.documentElement.setAttribute("color-scheme", "light");
    }

    document.querySelector("body")?.classList.add("body-tf-transform");

    // Get apiToken and userId from mobNative
    if (window.MobNative) {
      requestIdleCallback(() => {
        console.log("get fingerprint on requestIdleCallback");
        this.getFingerPrint();
        this.getApiToken(this.props);
      });
    } else {
      console.log("fallback with timeout");
      setTimeout(() => {
        this.getFingerPrint();
        this.getApiToken(this.props);
      }, 500);
    }
  };

  componentDidUpdate(prevState: any) {
    console.log('componentDidUpdate: ');
    console.log(this.props.location.pathname);
    // if (
    //   !this.state.apiToken &&
    //   !Cookies.get("apiToken") &&
    //   !this.props.location.pathname.includes("/login")
    // ) {
    //   let params = new URLSearchParams(this.props.location.search);
    //   if (this.state.loginPathName !== this.props.location.pathname) {
    //     this.setState({
    //       loginPathName: this.props.location.pathname,
    //       loginSearch: params,
    //     });
    //   }
    // }
  }

  componentWillMount() {
    this.currentLocation.current = { ...this.props.history.location };
    this.unlisten = this.props.history.listen(
      (location: any, action: "PUSH" | "POP" | "REPLACE") => {
        if (CONFIG.LOGIN_WITH_TUH_TRANSFORM) {
          if (!location.pathname.includes(TUH_TRANSFORM)) {
            this.props.history.replace({
              pathname:
                `${TUH_TRANSFORM}${location.pathname}` + location.search,
              state: location.state,
              search: location.search
            });
          } else if (action === "REPLACE") {
            return;
          }
        }

        this.controller.handleEvent({
          message: "HandleRouteChange",
          params: {
            currentPathname: this.currentLocation.current.pathname,
            nextPathname: location.pathname,
            action,
            location: this.currentLocation.current,
            history: this.props.history,
          },
        });
        this.currentLocation.current = { ...location };
      }
    );

    // Options for the observer (which mutations to observe)
  }
  componentWillUnmount() {
    this.unlisten();
  }

  getFingerPrint = () => {
    var options = {};
    Fingerprint2.getPromise(options).then((components: any) => {
      // components is array of {key: 'foo', value: 'component value'}
      var values = components.map(function (component: any) {
        return component.value;
      });
      var device_id = Fingerprint2.x64hash128(values.join(""), 31);
      this.setState({ device_id: device_id });
      console.log("MP Fingerprint id:", device_id);
      // Get subscription endpoint object
      createNotificationSubscription()
        .then((subscription) => {
          this.setState({ subscription: subscription });

          console.log("MP Success get notification subscription..");
          console.log(subscription);
        })
        .catch((err) => {
          console.log("MP error getSubScriptionObejct");
          console.error(
            "Couldn't create the notification subscription",
            err,
            "name:",
            err.name,
            "message:",
            err.message,
            "code:",
            err.code
          );
        });
    });
  };

  getApiToken = async (props: any) => {
    console.log(" getApiToken called");
    var apiToken;
    var userId;

    console.log("get apiToken from browser (cookies)");
    if (props) {
      apiToken = Cookies.get("apiToken");
      userId = Cookies.get("userId");

      this.setState({ apiToken: apiToken, userId: userId });
      this.controller.handleEvent({
        message: "GetPatientDetail",
        params: {},
      });
      this.setWebsocket({ apiToken: apiToken });

      console.log(" apiToken", apiToken);
      console.log(" userId", userId);
    }
  };

  setWebsocket = ({ apiToken }: any = {}) => {
    console.log("setWebsocket");
    console.log(apiToken, "apiToken");
    console.log(CONFIG.WS_HOST, "CONFIG.WS_HOST");
    if (apiToken) {
      console.log("setWebsocket connect");
      vcMessenger.connect(
        "MSG",
        {
          token: apiToken,
        },
        CONFIG.WS_HOST
      );
    }
  
  };

  handleChatChannelSelected = (path: string) => {
    this.setState({ disabledScrollIntoView: true });
    this.props.history.push({
      pathname: `${path}`,
      state: {},
    });

    setTimeout(() => {
      this.setState({ disabledScrollIntoView: false });
    }, 335);
  };

  handleRedirectChatChannelSelected = (path: string) => {
    window.location.assign(path);
  };

  handleChatSearchChange = async (e: any, v: InputProps) => {
    this.setState({ chatSearchValue: v.value });
  };

  handleUnfollowChannelSelected = (item: any) => {
    // 1 = dm,
    // 2 = mom and child

    let channel = item === 1 ? "DM" : "MOM";
    // alert("Waiting API Follow " + channel )
    if (item === 1) {
      window.location.assign(CONFIG.DM_BPLUS_URL);
    } else if (item === 2) {
      window.location.assign(CONFIG.MOM_BPLUS_URL);
    }
  };

  handleSetChatName = ({
    name,
    division,
  }: { name?: string; division?: number } = {}) => {
    this.setState({ chatName: name, chatDivision: division });
  };

  handleNavigationMessage = ({
    content = "",
    contentPayload = "",
    contentType,
    channelId,
  }: any = {}) => {
    if (content.includes("{หน้าโปรไฟล์}")) {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{หน้าโปรไฟล์}/g,
              `<a href='/profile'>หน้าโปรไฟล์</a>`
            ),
          }}
        />
      );
    } else if (content.includes("{ตารางออกตรวจผู้ให้คำปรึกษา}")) {
      return (
        <div
          onClick={() =>
            this.props.history.push({
              pathname: `/Appointment`,
              state: {
                channelId,
              },
            })
          }
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{ตารางออกตรวจผู้ให้คำปรึกษา}/g,
              "<a nohref>ตารางออกตรวจผู้ให้คำปรึกษา</a>"
              // "<a href='/Appointment'>ตารางออกตรวจผู้ให้คำปรึกษา</a>"
            ),
          }}
        />
      );
    } else if (content.includes("{กดเพื่อยืนยันการจัดส่งยา}")) {
      return (
        <div
          onClick={() =>
            this.props.history.push({
              pathname: `/drug-address-confirm/${contentPayload.encounter}/`,
              channelId,
            })
          }
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{กดเพื่อยืนยันการจัดส่งยา}/g,
              "<a nohref>กดเพื่อยืนยันการจัดส่งยา</a>"
            ),
          }}
        />
      );
    } else if (content.includes("{ดูประวัติการนัดหมาย}")) {
      return (
        <div
          onClick={() =>
            this.props.history.push({
              pathname: `/VCAppointmentView`,
              state: {
                channelId,
              },
            })
          }
          dangerouslySetInnerHTML={{
            __html: content.replace(
              /{ดูประวัติการนัดหมาย}/g,
              "<a nohref>ดูประวัติการนัดหมาย</a>"
              // "<a href='/VCAppointmentView'>ดูประวัติการนัดหมาย</a>"
            ),
          }}
        />
      );
    } else if (contentType === "bill") {
      let routePath = `/payment`;
      if (contentPayload.invoice_id) {
        routePath = `/paymentDetail/${contentPayload.invoice_id}`;
      }
      return (
        <div
          onClick={() =>
            this.props.history.push({
              pathname: routePath,
            })
          }
        >
          <a
            // @ts-ignore
            nohref
          >
            {content}
          </a>
        </div>
      );
    } else {
      return content;
    }
  };

  handleAddDiagRule = () => {
    this.props.history.push({
      pathname: `/diagrulesearch`,
      state: {},
    });
  };

  handleGoToLogin = () => {
    console.log(" handleGoToLogin /Login");
    this.props.history.push(
      `${TUH_TRANSFORM}/login` + document.location.search
    );
  };

  handleSelectDiagRule = (data: any) => {
    var obj = JSON.parse(data.content);
    this.setState({ targetForm: obj });
    this.setState({ targetDiagRule: data });
    this.props.history.push({
      pathname: `/form/${data.id}/`,
      state: {},
    });
  };

  handleGoBack = () => {
    if (this.state.profileStepperIndex === 2 && this.state.openAllergyProfile) {
      this.setState({ openAllergyProfile: false });
      return;
    }
    // two case , 2 in stepper profilecreate
    if (
      (this.state.profileStepperIndex || 0) > 0 &&
      (this.state.profileStepperIndex || 0) <= 2
    ) {
      let next = (this.state.profileStepperIndex || 0) - 1;
      this.setState({ profileStepperIndex: next });
      return;
    }

    if (
      this.currentLocation?.current?.pathname?.includes("/Chat/") &&
      this.state.previousPathname !== "/Chat"
    ) {
      this.props.history.push(`/Chat`);
    } else {
      this.props.history.goBack();
    }

    // this.setState({ backToApp: false });
    // if (!this.state.openChatBox) {
    //   this.props.history.push(`${TUH_TRANSFORM}/chat`);
    // } else {

    // }
    // else if (this.props.location.pathname.includes("profile")) {
    //   this.props.history.push(TUH_TRANSFORM);
    // } else if (this.props.location.pathname.includes("/payment/")) {
    //   this.props.history.push(TUH_TRANSFORM);
    // } else {
    //   this.props.history.goBack();
    // }
  };

  // Handle Menu Bar
  handleLogout = () => {
    console.log(" handleLogout");
    // Logout firebase
    this.controller.app.auth().signOut();

    serviceWorker.unregister();
    if (window.FB) {
      window.FB.logout(function (response: any) {
        console.log(" user Logout Facebook !");
      });
    }

    console.log("unregister service worker");
    // Remove data
    Cookies.remove("fullname", { path: "/" });
    Cookies.remove("username", { path: "/" });
    Cookies.remove("apiToken", { path: "/" });
    Cookies.remove("division_id", { path: "/" });
    Cookies.remove("userId", { path: "/" });
    Cookies.remove("project_name", { path: "/" });
    Cookies.remove("firstPageAns", { path: "/" });
    let params = new URLSearchParams(this.props.location.search);

    console.log("handleLogout Cookies.apiToken: ", Cookies.apiToken);
    console.log("handleLogout Cookies.userId: ", Cookies.userId);
    console.log(
      "handleLogout this.props.location.pathname: ",
      this.props.location.pathname
    );
    console.log("handleLogout params: ", params);
    this.setState({
      apiToken: Cookies.apiToken,
      userId: Cookies.userId,
      login: false,
      profile: false,
      openLogoutMod: false,
    });

    if (CONFIG.LOGIN_WITH_TUH_TRANSFORM) {
      this.props.history.push({
        pathname: "/tuh-transform/login" + document.location.search,
        state: {},
      });
    } else {
      this.props.history.push({
        pathname: "/Login" + document.location.search,
        state: {},
      });
    }

    if (typeof window.MobNative !== "undefined") {
      window.MobNative?.logout?.();
    } else if (typeof window.iosNative !== "undefined") {
      window.iosNative?.logout?.();
    }
  };

  handleAddHospitalProfile = () => {
    if (!this.chatBoxRef) {
      return;
    }
    let channelDetail = this.chatBoxRef.getChannelDetail();
    if (channelDetail && channelDetail.division) {
      this.setState(
        {
          chatDivision: channelDetail.division,
        },
        this.props.history.push({
          pathname: `/oa/${channelDetail.division}/`,
          state: {},
        })
      );
    }
  };

  handleOpenLogoutMod = () => {
    this.setState({ openSettingMod: false });
    this.setState({ openLogoutMod: !this.state.openLogoutMod });
  };

  // ProfileStepper
  handleChangeStep = (idx: number) => {
    this.setState({ profileStepperIndex: idx });
  };

  handleOpenAllergy = (isOpen: boolean) => {
    this.setState({ openAllergyProfile: isOpen });
  };

  handleOpenDeleteMod = () => {
    this.setState({ openSettingMod: false });
    this.setState({ openDeleteMod: !this.state.openDeleteMod });
  };

  handleSaveComplete = () => {
    this.props.history.push({
      pathname: `/Chat`,
      state: {},
    });
  };

  handleDeleteUser = async () => {
    console.log("this.state", this.state);

    let userId = this.state.userId as string;
    let apiToken = this.state.apiToken
      ? this.state.apiToken
      : Cookies.get("apiToken");
    console.log("apiToken: ", apiToken);

    console.log("userId", userId);
    // Clear Data
    const [data, error] = await this.props.profileController.postDeleteUser({
      apiToken,
      userId: userId ? parseInt(userId) : "",
    });

    if (window.FB) {
      window.FB.logout();
    }

    if (typeof window.iosNative !== "undefined") {
      window.iosNative.logout();
      return;
    }

    if (typeof window.MobNative !== "undefined") {
      window.MobNative.logout();
      return;
    }

    console.log("window.FB", window.FB);
    if (typeof window.FB !== "undefined") {
      console.log("Logout FB");
      window.FB.logout();
    }

    serviceWorker.unregister();
    console.log("unregister service worker");
    // Remove cookied
    Cookies.remove("apiToken", { path: "/" });
    Cookies.remove("project_name", { path: "/" });
    Cookies.remove("firstPageAns", { path: "/" });
    Cookies.remove("userId", { path: "/" });
    Cookies.remove("x-csrftoken");

    // Reset State
    this.setState({
      apiToken: undefined,
      userId: undefined,
      login: false,
      profile: false,
      openDeleteMod: false,
    });
    console.log(" remove all cookies", Cookies);
    if (CONFIG.LOGIN_WITH_TUH_TRANSFORM) {
      this.props.history.push({
        pathname: "/tuh-transform/login" + document.location.search,
        state: {},
      });
    } else {
      this.props.history.push({
        pathname: "/Login" + document.location.search,
        state: {},
      });
    }
  };

  handleSelectAddressBack = () => {
    if (this.selectAddressRef && this.selectAddressRef.goToDrugAddressConfirm) {
      this.selectAddressRef.goToDrugAddressConfirm();
    }
  };

  handleDrugAddressConfirmBack = () => {
    if (this.drugAddressConfirmRef && this.drugAddressConfirmRef.goBack) {
      this.drugAddressConfirmRef.goBack();
    }
  };

  handleOpenSettingMod = () => {
    this.setState({ openSettingMod: true });
  };

  handleCloseSettingMod = () => {
    this.setState({ openSettingMod: false });
  };

  handleGoToPayment = () => {
    this.props.history.push({ pathname: "/payment", state: {} });
  };

  handleUserProfile = () => {
    this.props.history.push({ pathname: "/profile", state: {} });
  };

  goToAppointment = () => {
    this.props.history.push("/Appointment");
  };

  gotoChatChannel = (chatChannelId: number) => {
    this.props.history.replace("/", { state: {} });
    if (chatChannelId) {
      let path = `/Chat/${chatChannelId}/`;
      this.handleChatChannelSelected(path);
    }
  };

  updateUnReadMessage = (message: any) => {
    let chatList = [...(this.state.channelList || [])];

    const chatIndex = chatList.findIndex(
      (item: any) => item.id === message.channel_id
    );

    if (chatIndex !== -1) {
      chatList[chatIndex].unread_message_count += 1;

      this.setState({
        channelList: chatList,
      });
    }
  };

  // for Chat --> dont want to fetch chat channel everytime
  loadChannelList = async () => {
    if (this.state.chatSearchValue) {
      return;
    }
    let apiToken = this.state.apiToken
      ? this.state.apiToken
      : this.controller.cookies.get("apiToken");
    let userId = this.state.userId
      ? this.state.userId
      : this.controller.cookies.get("userId");
    if (this.state.channelList?.length === 0) {
      this.setState({ chatLoading: true });
    }

    const [data, error] = await this.props.chatListController.getChatChanel(
      apiToken,
      userId
    );
    let sum_unread = 0;
    if (data) {
      if (
        data &&
        data.items &&
        Array.isArray(data.items) &&
        data.items.length > 0
      ) {
        console.log("data ", data);
        data.items.forEach((item: any) => {
          sum_unread += item.unread_message_count;
        });
      }
      this.showBadge(sum_unread);
      this.setState({ channelList: data.items, chatLoading: false });
    } else {
      this.showBadge(sum_unread);
      this.setState({ channelList: [], chatLoading: false });
    }
  };

  searchChannelList = async () => {
    let apiToken = this.state.apiToken
      ? this.state.apiToken
      : this.controller.cookies.get("apiToken");
    let userId = this.state.userId
      ? this.state.userId
      : this.controller.cookies.get("userId");
    // let search = inputRef.current.inputRef.current.value;
    let search = this.state.chatSearchValue;
    // if (this.state.channelList?.length === 0) {
    this.setState({ chatLoading: true });
    // }
    const [data, error] = await this.props.chatListController.searchChatChannel(
      apiToken,
      userId,
      search
    );
    this.setState({ chatLoading: false });
    if (data) {
      this.setState({ channelList: data.items });
    } else {
      this.setState({ channelList: [] });
    }
  };

  showBadge = (sum_unread: number) => {
    try {
      console.log("sum_unread = " + sum_unread);
      if (typeof window.iosNative !== "undefined") {
        window.iosNative.showBadge(sum_unread.toString());
      } else if (typeof window.MobNative !== "undefined") {
        window.MobNative.showBadge(sum_unread.toString());
      }
    } catch (e) {
      console.error("showBadge is not function...");
    }
  };

  getPropRoute = (pathRoute: string) => {
    const clearString = pathRoute.replace(/(\?|\/$)/gi, "");
    const regFormat = clearString.replace(
      /\/:\w+/g,
      (...res) => `(/(?<${res[0].slice(2)}>\\w+)|)`
    );
    const pathName = this.props.location.pathname;
    const exec = new RegExp(regFormat, "i").exec(pathName);
    const { groups } = exec || { groups: {} };
    const search = pathName.match(/\?.*/)?.[0];

    if (search) {
      this.props.location.search = search;
      this.props.history.location.search = search;
      this.props.location.pathname = pathName.replace(/\?.*/i, "");
      this.props.history.location.pathname = pathName.replace(/\?.*/i, "");
    }

    return {
      match: { params: groups },
      location: this.props.location,
      history: this.props.history,
    };
  };

  handleHaveApiToken(history: any) {
    history.push("/Chat");
  }

  goToVaccineAppointmentSlot = ({
    divisionId,
    divisionName,
    year,
    month,
    appointmentId,
  }: any = {}) => {
    this.props.history.push({
      pathname: "/VCAppointmentSlot/",
      state: {
        divisionId: divisionId,
        divisionName: divisionName,
        year: year,
        month: month,
        appointmentId,
      },
    });
  };

  goToDiagRule = ({ diag_url }: any = {}) => {
    this.props.history.push(diag_url);
  };

  goToVaccineAppointmentView = () => {
    this.props.history.push("/VCAppointmentView");
  };

  render = () => {
    console.log("MobTransform render:", this);

    return (
      <IntlProvider
        locale="th-TH"
        // messages={flattenMessages(messages["en-US"])}
        // messages={flattenMessages(
        //   messages[this.state.language || LANGUAGE.EN_US]
        // )}
        messages={this.state.language?.includes("TH") ? thMessage : enMessage}
      >
        <div style={{}} className="tf-transform">
          <ModConfirm
            open={this.state.openLogoutMod}
            // alertText="ต้องการออกจากระบบใช่หรือไม่"
            titleName={<FormattedMessage id="common.warning" />}
            alertText={<FormattedMessage id="auth.ask_logout" />}
            buttonLeft={<FormattedMessage id="common.cancel" />}
            buttonRight={<FormattedMessage id="common.confirm" />}
            onButtonLeftClick={this.handleOpenLogoutMod}
            onButtonRightClick={this.handleLogout}
          />

          <ModConfirm
            open={this.state.openDeleteMod}
            // alertText="ต้องการออกจากระบบใช่หรือไม่"
            titleName={<FormattedMessage id="common.warning" />}
            alertText={<FormattedMessage id="auth.ask_delete" />}
            buttonLeft={<FormattedMessage id="common.cancel" />}
            buttonRight={<FormattedMessage id="common.confirm" />}
            onButtonLeftClick={this.handleOpenDeleteMod}
            onButtonRightClick={this.handleDeleteUser}
          />

          <MainVideoCall.Main
            // onEvent={this.controller.handleEvent}
            // setProp={this.controller.setProp}
            openVideoCallModal={this.state.openVideoCallModal}
            videoCallRoom={this.state.videoCallRoom}
            isPatient={true}
            username={this.state.patientData.patient_name ?? ""}
            onCloseVideoCall={() =>
              this.setState({
                openVideoCallModal: false,
                videoCallRoom: "",
              })
            }
          />

          <SlideRoutes
            duration={this.state.isMount ? 350 : 0}
            location={this.props.location}
          >
            <Route
              path="/tuh-transform/login"
              render={(props) => {
                return (
                  <>
                    <TFLogin
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      inputErrorLogin={this.state.inputErrorLogin}
                      loadingMainTransform={this.state.loadingMainTransform}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      onHaveApiToken={this.handleHaveApiToken}
                      setDevice={this.state.setDevice}
                      deviceId={this.state.device_id}
                      userSubscription={this.state.subscription}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/register"
              render={(props) => {
                return (
                  <>
                    <TFRegister
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      viewIndexRegister={this.state.viewIndexRegister}
                      registerInfo={this.state.registerInfo}
                      firstRegistration={this.state.firstRegistration}
                      loadingMainTransform={this.state.loadingMainTransform}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      modMessageType={this.state.modMessageType}
                      onHaveApiToken={this.handleHaveApiToken}
                      setDevice={this.state.setDevice}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/sign-in-email"
              render={(props) => {
                return (
                  <>
                    <TFSignInEmail
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      inputEmail={this.state.inputEmail}
                      inputEmailError={this.state.inputEmailError}
                      signInByEmailError={this.state.signInByEmailError}
                      registerInfo={this.state.registerInfo}
                      viewIndexRegister={this.state.viewIndexRegister}
                      loadingMainTransform={this.state.loadingMainTransform}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      modMessageType={this.state.modMessageType}
                      onHaveApiToken={this.handleHaveApiToken}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/verify-identity"
              render={(props) => {
                return (
                  <>
                    <TFVerifyIdentity
                      onEvent={this.controller.handleEvent}
                      userProfile={this.state.userProfile}
                      userId={this.state.userProfile?.id || ""}
                      history={this.props.history}
                      openModVerify={this.state.openModVerify}
                      loadingMainTransform={this.state.loadingMainTransform}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      onHaveApiToken={this.handleHaveApiToken}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/password"
              render={(props) => {
                return (
                  <>
                    <TFForgetPassword
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      inputEmailRequest={this.state.inputEmailRequest}
                      openModCheckEmail={this.state.openModCheckEmail}
                      registerInfo={this.state.registerInfo}
                      changePasswordData={this.state.changePasswordData}
                      successMessage={this.state.successMessage}
                      errorMessage={this.state.errorMessage}
                      modMessageType={this.state.modMessageType}
                      loadingMainTransform={this.state.loadingMainTransform}
                      onHaveApiToken={this.handleHaveApiToken}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/edit-patient-profile"
              render={(props) => {
                return (
                  <>
                    <TFEditProfile
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      registerInfo={this.state.registerInfo}
                      successMessage={this.state.successMessage}
                      errorMessage={this.state.errorMessage}
                      loadingMainTransform={this.state.loadingMainTransform}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/edit-patient-hn"
              render={(props) => {
                return (
                  <>
                    <TFEditHN
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      registerInfo={this.state.registerInfo}
                      successMessage={this.state.successMessage}
                      errorMessage={this.state.errorMessage}
                      loadingMainTransform={this.state.loadingMainTransform}
                    />
                  </>
                );
              }}
            />
            <Route
              path="/tuh-transform/profile"
              render={() => {
                return (
                  <>
                    <div
                      className="tf-profile"
                      style={{
                        width: "100vw",
                        height: "calc(100vh)",
                        overflow: "auto",
                        paddingBottom: "70px",
                      }}
                    >
                      <TFHead
                        title={"Profile"}
                        // leftIconClick={this.handleGoBack}
                        hideLeftIcon
                      />
                      {CONFIG.ISHEALTH_PLATFORM_PROFILE ? (
                        <ProfileWrap
                          // isCompleteProfile={this.state.isCompleteProfile}
                          hideLogout={
                            this.state.projectName ===
                            PROJECT_NAME.M_B_PLUS_TELEMED
                          }
                          hideDeleteUser={true}
                          noApiToken={this.handleGoToLogin}
                          apiToken={this.state.apiToken}
                          controller={this.props.profileController}
                          onLogout={this.handleOpenLogoutMod}
                          handleGoBack={this.handleGoBack}
                          // saveComplete={()=>{this.setState({isCompleteProfile: true})}}
                          profileStepperIndex={this.state.profileStepperIndex}
                          onChangeProfileStepper={this.handleChangeStep}
                          openAllergyProfile={this.state.openAllergyProfile}
                          onSetOpenAllergy={this.handleOpenAllergy}
                          onDeleteUser={this.handleOpenDeleteMod}
                          saveComplete={this.handleSaveComplete}
                          onEvent={this.controller.handleEvent}
                          userProfileData={this.state.userProfileData}
                        />
                      ) : (
                        <Profile
                          hideLogout={
                            this.state.projectName ===
                            PROJECT_NAME.M_B_PLUS_TELEMED
                          }
                          hideDeleteUser={true}
                          noApiToken={this.handleGoToLogin}
                          apiToken={this.state.apiToken}
                          controller={this.props.profileController}
                          onLogout={this.handleOpenLogoutMod}
                          handleGoBack={this.handleGoBack}
                          onDeleteUser={this.handleOpenDeleteMod}
                        />
                      )}

                      <TFBottomNavigation
                        onEvent={this.controller.handleEvent}
                        history={this.props.history}
                        activeItem="profile"
                        initialInnerScreen={this.state.initialInnerScreen}
                      />
                    </div>
                  </>
                );
              }}
            ></Route>
            <Route
              exact
              path="/tuh-transform/Appointment/:appointId"
              render={(props) => {
                console.log(" Router  /Appointment/:appointId/ props: ", props);
                return (
                  <div
                    style={{
                      width: "100vw",
                      height: "calc(100vh)",
                      overflow: "auto",
                      paddingBottom: "70px",
                    }}
                  >
                    <TFHead leftIconClick={this.handleGoBack} />
                    <PatientSelectAppointment
                      {...this.getPropRoute(
                        "/tuh-transform/Appointment/:appointId"
                      )}
                      noApiToken={this.handleGoToLogin}
                      apiToken={this.state.apiToken}
                      controller={this.props.queueController}
                    />
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/CreateAppointmentIH/:appointStatus/:appointId"
              render={(props) => {
                console.log(
                  " Router  /CreateAppointmentIH/:appointStatus/:appointId/ props: ",
                  props
                );
                return (
                  <div
                    style={{
                      width: "100vw",
                      overflow: "auto",
                      paddingBottom: "70px",
                    }}
                  >
                    <PatientCreateAppointmentIH
                      {...this.getPropRoute(
                        "/tuh-transform/CreateAppointmentIH/:appointStatus/:appointId"
                      )}
                      noApiToken={this.handleGoToLogin}
                      apiToken={this.state.apiToken}
                      controller={this.props.queueController}
                      handleGoBack={this.handleGoBack}
                      appointmentData={this.state.appointmentData}
                      createAppointment={this.state.createAppointment}
                      divisionList={this.state.divisionList}
                      doctorList={this.state.doctorList}
                      timeDivision={this.state.timeDivision}
                      userProfile={this.state.userProfile}
                      setProp={this.controller.setProp}
                      onEvent={this.controller.handleEvent}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                      patientAppointmentList={this.state.patientAppointmentList}
                    />
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/EditAppointmentIH/:appointId"
              render={(props) => {
                console.log(
                  " Router  /EditAppointmentIH/:appointId/ props: ",
                  props
                );
                return (
                  <div
                    style={{
                      width: "100vw",
                      overflow: "auto",
                      paddingBottom: "70px",
                    }}
                  >
                    <PatientEditAppointmentIH
                      {...this.getPropRoute(
                        "/tuh-transform/EditAppointmentIH/:appointId"
                      )}
                      noApiToken={this.handleGoToLogin}
                      apiToken={this.state.apiToken}
                      controller={this.props.queueController}
                      handleGoBack={this.handleGoBack}
                      appointmentData={this.state.appointmentData}
                      createAppointment={this.state.createAppointment}
                      divisionList={this.state.divisionList}
                      doctorList={this.state.doctorList}
                      timeDivision={this.state.timeDivision}
                      setProp={this.controller.setProp}
                      onEvent={this.controller.handleEvent}
                      errorMessage={this.state.errorMessage}
                      successMessage={this.state.successMessage}
                    />
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/Appointment/:appointId"
              render={(props) => {
                console.log(" Router  /Appointment/:appointId/ props: ", props);
                return (
                  <div
                    style={{
                      width: "100vw",
                      height: "calc(100vh)",
                      overflow: "auto",
                      paddingBottom: "70px",
                    }}
                  >
                    <TFHead leftIconClick={this.handleGoBack} />
                    <PatientSelectAppointment
                      {...this.getPropRoute(
                        "/tuh-transform/Appointment/:appointId"
                      )}
                      noApiToken={this.handleGoToLogin}
                      apiToken={this.state.apiToken}
                      controller={this.props.queueController}
                    />
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/VCAppointmentSlot"
              render={() => {
                const props = this.getPropRoute(
                  "/tuh-transform/VCAppointmentSlot"
                );
                console.log(" Router  /VCAppointmentSlot/ props: ", props);

                return (
                  <>
                    <VCAppointmentSlot
                      {...props}
                      header={
                        <TFHead
                          // title={CONFIG.COMPANY}
                          leftIconClick={this.handleGoBack}
                        />
                      }
                      // @ts-ignore
                      noApiToken={this.handleGoToLogin}
                      controller={this.props.vcAppController}
                      apiToken={this.state.apiToken || ""}
                      params={props.location?.state}
                      goToDiagRule={this.goToDiagRule}
                      goToVaccineAppointmentView={
                        this.goToVaccineAppointmentView
                      }
                    ></VCAppointmentSlot>
                  </>
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/VCAppointmentView"
              render={(props) => {
                return (
                  <VCAppointmentView
                    header={
                      <TFHead
                        // title={CONFIG.COMPANY}
                        leftIconClick={this.handleGoBack}
                      />
                    }
                    patientData={this.state.patientData}
                    // @ts-ignore
                    controller={this.props.vcAppController}
                    queueController={this.props.queueController}
                    goToVaccineAppointmentSlot={this.goToVaccineAppointmentSlot}
                  />
                );
              }}
            />

            <Route
              exact
              path="/tuh-transform/MakeAppointment/:shippingOder"
              render={(props) => {
                console.log(" Router  /MakeAppointment/ props: ", props);
                return (
                  <>
                    <TFHead
                      title={"นัดหมาย"}
                      leftIconClick={() => {
                        this.handleGoBack();
                      }}
                    />
                    <MakeAppointment
                      {...this.getPropRoute(
                        "/tuh-transform/MakeAppointment/:shippingOder"
                      )}
                      channelList={this.state.channelList}
                      onEvent={this.controller.handleEvent}
                      divisionServiceBlockList={
                        this.state.divisionServiceBlockList
                      }
                      divisionServiceBlockConfirm={
                        this.state.divisionServiceBlockConfirm
                      }
                    />
                  </>
                );
              }}
            />

            <Route
              path="/tuh-transform/ConfirmShipping"
              render={(props) => {
                console.log(" Router  /scanqr/ props: ", props);
                const stateLocation = useLocation();
                console.log(" Router  /props.location.state : ", stateLocation);
                return (
                  <div
                    style={{
                      height: "100%",
                    }}
                  >
                    <TFHead
                      title={"รับยา"}
                      leftIconClick={() => {
                        this.setState({ setQRData: null });
                        this.handleGoBack();
                      }}
                    />

                    <ConfirmShipping
                      selectedOrder={stateLocation.state}
                      patientData={this.state.patientData}
                      onEvent={this.controller.handleEvent}
                      confirmShipping={this.state.confirmShipping}
                      shippingList={this.state.shippingList}
                      setQRData={this.state.setQRData}
                    />
                  </div>
                );
              }}
            />

            <Route
              path="/tuh-transform/shippinglist"
              render={(props) => {
                console.log(" Router  /shippinglist/ props: ", props);
                return (
                  <div>
                    <ShippingList
                      onEvent={this.controller.handleEvent}
                      patientData={this.state.patientData}
                      shippingList={this.state.shippingList}
                      header={<TFHead title={"รับยา"} hideLeftIcon />}
                      history={this.props.history}
                    />
                    <TFBottomNavigation
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      activeItem="shippinglist"
                      initialInnerScreen={this.state.initialInnerScreen}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/tuh-transform/AppointmentIH"
              render={(props) => {
                console.log(" Router  /AppointmentIH/ props:: ", props);

                return (
                  <div>
                    <TFHead
                      leftIconClick={this.handleGoBack}
                      title={"ระบุแพทย์หรือ คลินิก"}
                    />
                    <PatientSelectAppointmentIH
                      {...this.getPropRoute("/tuh-transform/AppointmentIH/")}
                      noApiToken={this.handleGoToLogin}
                      apiToken={this.state.apiToken}
                      controller={this.props.queueController}
                      location={this.props.location}
                      divisionList={this.state.divisionList}
                      doctorList={this.state.doctorList}
                      setProp={this.controller.setProp}
                      onEvent={this.controller.handleEvent}
                    />
                    <TFBottomNavigation
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      activeItem="appointment"
                      initialInnerScreen={this.state.initialInnerScreen}
                    />
                  </div>
                );
              }}
            />
            <Route
              path="/tuh-transform/Appointment"
              render={(props) => {
                console.log(" Router  /Appointment/ props:: ", props);

                return (
                  <div>
                    {CONFIG.PATIENT_APPOINTMENT === "PatientAppointmentV3" ? (
                      <PatientAppointmentPatient
                        history={this.props.history}
                        location={this.props.location}
                        header={
                          <TFHead
                            title={
                              <FormattedMessage id={"appoint.appointment"} />
                            }
                            hideLeftIcon
                          />
                        }
                        noApiToken={this.handleGoToLogin}
                        apiToken={this.state.apiToken}
                        patientData={this.state.patientData}
                        controller={this.props.queueController}
                        handleGoBack={this.handleGoBack}
                        setProp={this.controller.setProp}
                        onEvent={this.controller.handleEvent}
                        patientAppointmentList={this.state.patientAppointmentList}
                      />
                    ) : (
                      <TimePatient
                        // {...props}
                        history={this.props.history}
                        location={this.props.location}
                        header={<TFHead title={"Appointment"} hideLeftIcon />}
                        noApiToken={this.handleGoToLogin}
                        apiToken={this.state.apiToken}
                        patientData={this.state.patientData}
                        controller={this.props.queueController}
                        handleGoBack={this.handleGoBack}
                        displayDatetimeIso={true}
                      />
                    )}
                    <TFBottomNavigation
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      activeItem="appointment"
                      initialInnerScreen={this.state.initialInnerScreen}
                    />
                  </div>
                );
              }}
            />

            <Route
              exact
              path="/tuh-transform/drug-address-confirm/:encounterId/"
              render={(props) => {
                return (
                  <DrugAddressConfirm
                    {...this.getPropRoute(
                      "/tuh-transform/drug-address-confirm/:encounterId/"
                    )}
                    ref={(ref: any) => (this.drugAddressConfirmRef = ref)}
                    menuBar={
                      <TFHead
                        leftLabel={
                          <FormattedMessage id="drug_delivery.confirm_drug_address" />
                        }
                        leftIconClick={this.handleDrugAddressConfirmBack}
                      />
                    }
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.props.addressController}
                    noApiToken={this.getApiToken}
                    onCancel={this.handleDrugAddressConfirmBack}
                  />
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/select-address/:encounterId?/"
              render={(props) => {
                return (
                  <SelectAddress
                    {...this.getPropRoute(
                      "/tuh-transform/select-address/:encounterId?/"
                    )}
                    ref={(ref: any) => (this.selectAddressRef = ref)}
                    menuBar={
                      <TFHead
                        leftLabel={
                          <FormattedMessage id="drug_delivery.select_address" />
                        }
                        leftIconClick={this.handleSelectAddressBack}
                      />
                    }
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.props.addressController}
                    noApiToken={this.getApiToken}
                  />
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/edit-address/:addressId/"
              render={(props) => {
                return (
                  <EditAddress
                    {...this.getPropRoute(
                      "/tuh-transform/edit-address/:addressId/"
                    )}
                    menuBar={
                      <TFHead
                        leftLabel={
                          <FormattedMessage id="drug_delivery.edit_address" />
                        }
                        leftIconClick={this.handleGoBack}
                      />
                    }
                    apiToken={this.state.apiToken}
                    patientData={this.state.patientData}
                    controller={this.props.addressController}
                    noApiToken={this.getApiToken}
                  />
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/edit-address/"
              render={(props) => (
                <EditAddress
                  // {...props}
                  match={{ params: {} }}
                  history={this.props.history}
                  location={this.props.location}
                  menuBar={
                    <TFHead
                      leftLabel={
                        <FormattedMessage id="drug_delivery.add_edit_address" />
                      }
                      leftIconClick={this.handleGoBack}
                    />
                  }
                  apiToken={this.state.apiToken}
                  patientData={this.state.patientData}
                  controller={this.props.addressController}
                  noApiToken={this.getApiToken}
                />
              )}
            />

            <Route
              exact
              path="/tuh-transform/form/:id/"
              render={(props) => {
                return (
                  <div className="App-main">
                    <div className="App-content">
                      <TFHead leftIconClick={this.handleGoBack} />
                      <DiagForm
                        {...this.getPropRoute("/tuh-transform/form/:id/")}
                        noApiToken={this.handleGoToLogin}
                        apiToken={this.state.apiToken}
                        controller={this.props.diagFormController}
                        diagRule={this.state.targetDiagRule}
                        onFinished={this.gotoChatChannel}
                      />
                    </div>
                  </div>
                );
              }}
            />

            <Route
              path="/tuh-transform/diagrulesearch"
              render={() => {
                return (
                  <div>
                    <TFHead leftIconClick={this.handleGoBack} />
                    <DiagRuleSearch
                      noApiToken={this.handleGoToLogin}
                      apiToken={this.state.apiToken}
                      controller={this.props.diagRuleController}
                      onSeleted={this.handleSelectDiagRule}
                      handleGoBack={this.handleGoBack}
                    />
                  </div>
                );
              }}
            />

            <Route
              exact
              path="/tuh-transform/OA/:id"
              render={(props) => {
                return (
                  <div>
                    <TFHead leftIconClick={this.handleGoBack} />
                    <OfficialAccount
                      {...this.getPropRoute("/tuh-transform/OA/:id")}
                      apiToken={this.state.apiToken}
                      division={this.state.chatDivision}
                      controller={this.props.officialAccountController}
                      handleGoBack={this.handleGoBack}
                      onSetBackToApp={(value: boolean) => {
                        this.setState({ backToApp: value });
                      }}
                    />
                  </div>
                );
              }}
            ></Route>

            <Route
              path="/tuh-transform/Chat/:chatChannelId/"
              render={(props) => {
                return (
                  <div className="chat-box-wrapper tf-chat-box">
                    <TFHead
                      rightIcon="file alternate"
                      leftLabel={
                        this.state.chatName && this.state.chatName.length < 20
                          ? this.state.chatName
                          : this.state.chatName
                          ? this.state.chatName
                          : "Unknown"
                      }
                      leftIconClick={() => {
                        this.handleGoBack();
                      }}
                      rightIconClick={this.handleAddHospitalProfile}
                    />
                    <ChatBox
                      {...this.getPropRoute(
                        "/tuh-transform/Chat/:chatChannelId/"
                      )}
                      division={this.state.chatDivision}
                      ref={(ref) => (this.chatBoxRef = ref)}
                      noApiToken={this.getApiToken}
                      apiToken={this.state.apiToken}
                      userId={this.state.userId}
                      patientData={this.state.patientData}
                      controller={this.props.chatController}
                      useNormalTextAreaKey={true}
                      onSetChannelName={this.handleSetChatName}
                      onNavigationMessage={this.handleNavigationMessage}
                      onSetBackToApp={(value: boolean) => {
                        this.setState({ backToApp: value });
                      }}
                      disabledScrollIntoView={this.state.disabledScrollIntoView}
                      onOpenVideoCall={(url: string) =>
                        this.setState({
                          openVideoCallModal: true,
                          videoCallRoom: url,
                        })
                      }
                    />
                  </div>
                );
              }}
            />

            <Route
              exact
              path="/tuh-transform/payment/:divisionId?/"
              render={(props) => {
                return (
                  <div>
                    <PatientPayment
                      {...this.getPropRoute(
                        "/tuh-transform/payment/:divisionId?/"
                      )}
                      apiToken={this.state.apiToken}
                      controller={this.props.paymentController}
                      patientData={this.state.patientData}
                      onSetBackToApp={(value: boolean) => {
                        this.setState({ backToApp: value });
                      }}
                      // onNoPatientId={this.getPatientDetail}
                      noApiToken={this.getApiToken}
                      menuBar={
                        <TFHead
                          title={"Payment"}
                          leftIconClick={this.handleGoBack}
                        />
                      }
                    />
                    {/* <TFBottomNavigation
                      history={this.props.history}
                      activeItem="payment"
                    /> */}
                  </div>
                );
              }}
            />
            <Route
              exact
              path="/tuh-transform/paymentDetail/:id/"
              render={(props) => {
                return (
                  <PatientPaymentDetail
                    {...this.getPropRoute("/tuh-transform/paymentDetail/:id/")}
                    apiToken={this.state.apiToken}
                    controller={this.props.paymentController}
                    patientData={this.state.patientData}
                    menuBar={<TFHead leftIconClick={this.handleGoBack} />}
                  />
                );
              }}
            />
            <Route
              path="/tuh-transform/other"
              render={(props) => {
                return (
                  <div>
                    Other
                    <TFBottomNavigation
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      activeItem="other"
                      initialInnerScreen={this.state.initialInnerScreen}
                    />
                  </div>
                );
              }}
            />

            <Route
              path="/tuh-transform/"
              render={(props) => {
                return (
                  <div className="tf-transform-chat">
                    <TFHead
                      title={"Chat"}
                      leftIcon={
                        <SettingPopup
                          onOpen={this.handleOpenSettingMod}
                          onClose={this.handleCloseSettingMod}
                          open={this.state.openSettingMod}
                          handleUserProfile={this.handleUserProfile}
                          handleGoToPayment={this.handleGoToPayment}
                          goToAppointment={this.goToAppointment}
                          goToVaccineAppointment={() => {}}
                          goToVaccineAppointmentView={() => {}}
                          handleLogout={this.handleOpenLogoutMod}
                          hidePayment={true}
                        />
                      }
                      rightIcon="hospital"
                      rightIconClick={this.handleAddDiagRule}
                    />
                    <TFChatList
                      ref={(ref: any) => (this.chatListRef = ref?.chatListRef)}
                      apiToken={this.state.apiToken}
                      userId={this.state.userId}
                      chatListController={this.props.chatListController}
                      noApiToken={this.getApiToken}
                      // noApiToken={this.handleGoToLogin}
                      isLoading={this.state.chatLoading}
                      onChannelSelected={this.handleChatChannelSelected}
                      onRedirectedChannelSelected={
                        this.handleRedirectChatChannelSelected
                      }
                      loadChannelList={this.loadChannelList}
                      channelList={this.state.channelList}
                      onSearchChange={this.handleChatSearchChange}
                      searchChannelList={this.searchChannelList}
                      chatSearchValue={this.state.chatSearchValue}
                      onUnfollowChannelSelected={
                        this.handleUnfollowChannelSelected
                      }
                    />
                    <TFBottomNavigation
                      onEvent={this.controller.handleEvent}
                      history={this.props.history}
                      activeItem="chat"
                      initialInnerScreen={this.state.initialInnerScreen}
                    />
                  </div>
                );
              }}
            />
          </SlideRoutes>
        </div>
      </IntlProvider>
    );
  };
}


export default withRouter(MobTransform);

