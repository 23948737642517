import React, { CSSProperties } from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Accordion,
  Icon,
  Label,
  Header,
  Dimmer,
  Loader,
} from "semantic-ui-react";

const zeroPadding = {
  padding: "0px",
};

const zeroMargin = {
  margin: "0px",
};

const contentStyle = {
  paddingTop: "0px",
  paddingBottom: "1em",
};

const zeroBottomMargin = {
  marginBottom: "0px",
};

const flexWrapMarginRight20PX: CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  marginRight: "20px",
  alignItems: "center",
};

const flexWrapMarginCenter: CSSProperties = {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "center",
};

const onTopRight = {
  position: "absolute",
  right: "5px",
};

const rotateToMinus90 = {
  transform: "rotate(-90deg)",
  transition: "transform 0.15s linear",
};

const rotateToZero = {
  transform: "rotate(0deg)",
  transition: "transform 0.15s linear",
};

class CardLayout extends React.PureComponent<any, any> {
  static propTypes: any;
  static defaultProps: any;

  temporarilyPreventToggling: any;

  constructor(props: any) {
    super(props);
    this.state = {
      active: props.active,
      closeable: props.closeable,
    };

    // used for temporary preventing the accordion from being toggled
    // when a user click any component on the titleContent
    this.temporarilyPreventToggling = false;
  }

  handleTitleClick = (e: any) => {
    if (this.temporarilyPreventToggling) {
      this.temporarilyPreventToggling = false;
      return;
    }
    if (this.props.toggleable) {
      this.setState((prev: any) => ({ active: !prev.active }));
    }
  };

  handleTitleContentClick = () => {
    this.temporarilyPreventToggling = true;
  };

  handleIconClick = () => {
    this.temporarilyPreventToggling = true;
    if (this.props.closeable) {
      this.props.onClose();
    }
  };

  render() {
    const {
      style,
      children,
      titleContent,
      ribbon,
      closeable,
      titleText,
      loading,
      ribbonColor,
      hideTitleText,
      hideTitleContent,
      hideContent,
      hideDivider,
      hideHeaderIcon,
      readOnly,
      headerColor,
      loadingText,
      defaultBackground,
      topRightText,
      hideTopRightText,
      enableMargin,
      cardLayoutClassName,
      fullWidth,
      dividerStyle,
      dimmerStyle,
      centerTitle,
      titleColor,
    } = this.props;

    const { active } = this.state;

    return (
      <Dimmer.Dimmable
        dimmed={loading}
        className={
          enableMargin
            ? "CardLayout " + cardLayoutClassName
            : "CardLayout noCardMargin " + cardLayoutClassName
        }
        style={dimmerStyle}
      >
        <Accordion
          styled
          fluid
          className={`${headerColor} raised segment ${
            defaultBackground ? "defaultBackground" : null
          }`}
          style={{ ...zeroPadding, ...zeroMargin, ...style }}
        >
          <Accordion.Title
            active={active}
            onClick={this.handleTitleClick}
            style={{
              background: titleColor ? titleColor : "",
            }}
          >
            {!hideHeaderIcon && (
              <Icon
                size="large"
                style={{
                  ...onTopRight,
                  ...(active ? rotateToMinus90 : rotateToZero),
                }}
                color={closeable ? "red" : "black"}
                name={closeable ? "close" : "caret left"}
                onClick={this.handleIconClick}
              />
            )}

            {!hideTopRightText && (
              <Header style={{ ...onTopRight, marginRight: 5 }}>
                {topRightText}
              </Header>
            )}

            <div
              style={
                centerTitle ? flexWrapMarginCenter : flexWrapMarginRight20PX
              }
            >
              {!hideTitleText &&
                (ribbon ? (
                  <Label ribbon color={ribbonColor}>
                    {titleText}
                  </Label>
                ) : centerTitle ? (
                  <Header style={{ margin: "2px" }}>{titleText}</Header>
                ) : (
                  <Header style={zeroBottomMargin}>{titleText}</Header>
                ))}

              <div style={{ flexGrow: centerTitle ? 0 : 1 }} />

              {!hideTitleContent && (
                <div onClick={this.handleTitleContentClick}>{titleContent}</div>
              )}
            </div>

            <Divider hidden={!active || hideDivider} style={dividerStyle} />
          </Accordion.Title>

          <Accordion.Content
            active={active}
            style={{
              ...contentStyle,
              ...(fullWidth ? { paddingRight: 0, paddingLeft: 0 } : {}),
            }}
          >
            {!hideContent && children}
          </Accordion.Content>
        </Accordion>

        <Dimmer active={readOnly} inverted />
        <Dimmer active={loading} inverted>
          <Loader inverted>{loadingText}</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    );
  }
}

CardLayout.propTypes = {
  // ReactElement
  content: PropTypes.element,
  titleContent: PropTypes.element,

  // String
  titleText: PropTypes.string,
  ribbonColor: PropTypes.string,
  headerColor: PropTypes.string,
  loadingText: PropTypes.string,
  topRightText: PropTypes.string,

  // Boolean
  hideTitleText: PropTypes.bool,
  hideTitleContent: PropTypes.bool,
  hideDivider: PropTypes.bool,
  hideContent: PropTypes.bool,
  hideHeaderIcon: PropTypes.bool,
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  active: PropTypes.bool,
  ribbon: PropTypes.bool,
  loading: PropTypes.bool,
  readOnly: PropTypes.bool,
  hideTopRightText: PropTypes.bool,
  enableMargin: PropTypes.bool,
  fullWidth: PropTypes.bool,
  dividerStyle: PropTypes.object,
  dimmerStyle: PropTypes.object,
  centerTitle: PropTypes.object,
  titleColor: PropTypes.string,

  // Function
  onClose: PropTypes.func,

  // We're still not sure whether we need these properties or not
  // allowClose: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
  // hideCallback: PropTypes.func
  // showCallback: PropTypes.func
};

CardLayout.defaultProps = {
  headerColor: "blue",
  loadingText: "Loading",
  toggleable: true,
  active: true,
  closeable: true,
  onClose: () => {},
  defaultBackground: true,
  topRightText: "",
  hideTopRightText: true,
  enableMargin: true,
  fullWidth: false,
  cardLayoutClassName: "",
  dividerStyle: {},
  dimmerStyle: {},
};

export default CardLayout;
