import React, {
  useState,
  useRef,
  SyntheticEvent,
  useEffect,
  useMemo,
} from "react";
import {
  Menu,
  Button,
  Divider,
  Form,
  Input,
  DropdownProps,
  Tab,
  TabProps,
  Checkbox,
  Grid,
  Image,
  Icon,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { QueueController } from "./Time";
import "./Time.css";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { injectIntl, IntlShape } from "react-intl";
import SwipeableViews from "react-swipeable-views";
import "../../css/PatientSelectAppointmentIH.scss";
import personImg from "react-lib/apps/common/person.png";
import { makeStyles } from "@mui/styles";

interface RouterProps {
  // type for `match.params`
  appointId: string; // must be type `string` since value comes from the URL
  appointStatus: string; // must be type `string` since value comes from the URL
}

interface PatientSelectAppointmentIHProps
  extends RouteComponentProps<RouterProps, any, { channelId?: string }> {
  apiToken?: string;
  controller?: QueueController;
  noApiToken?: () => {};
  divisionId?: number | string;
  intl?: IntlShape;
  divisionList?: any;
  doctorList?: any;
  setProps?: any;
  onEvent?: any;
}

const useStyles = makeStyles((theme) => ({
  panes: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "5px 0px",
  },
  cadeBox: {
    borderRadius: "10px",
    margin: "10px",
    boxSizing: "border-box",
    border: "0.5px solid rgba(227,239,255,0.5)",
    boxShadow: "3px 3px 0px rgba(227,239,255,0.5)",
  },
}));

const COLOR = {
  primary: "var(--primary-theme-color)",
  primary_font: "var(--primary-font-color)",
  grey: `#9e9e9e`,
};

const IMAGE = {
  imageBgUpload: "public/logo_ishealth_circle.png",
};

const PatientSelectAppointmentIH = (props: PatientSelectAppointmentIHProps) => {
  const classes = useStyles();
  const history = useHistory();
  const [divisionList, setDivisionList] = useState();
  const [doctorList, setDoctorList] = useState();
  const [viewIndex, setViewIndex] = useState<number | string>(0);
  const [searchDoctor, setSearchDoctor] = useState("");
  const [searchDivision, setSearchDivision] = useState("");
  const [isTelemed, setIsTelemed] = useState<boolean>(false)
  const containerRef = useRef<any>();
  const panes = useMemo(
    () => [
      {
        menuItem: (
          <Menu.Item key="doctor" className={classes.panes}>
            แพทย์
          </Menu.Item>
        ),
      },
      {
        menuItem: (
          <Menu.Item key="department" className={classes.panes}>
            แผนก
          </Menu.Item>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    props.onEvent({
      message: "HandleDivisionList",
      params: {only_have_dsb: true}
    });
    props.onEvent({
      message: "HandleDoctorList",
      params: {}
    });
  }, []);

  useEffect(() => {
    setDivisionList(props.divisionList);
    setDoctorList(props.doctorList);
  }, [props.divisionList, props.doctorList]);

  const handleCreateAppointmentIH = (status: any, id: any) => {
    history.push(
      `/tuh-transform/CreateAppointmentIH/${status}/${id}/`,
      props.location.state
    );
  };

  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDoctorSearchChange = (event: any, data: any) => {
    if(!data.value){
      props.onEvent({
        message: "HandleDoctorList",
        params: {isTelemed}
      });
    }else if(data.value.length > 2){
      props.onEvent({
        message: "HandleDoctorList",
        params: {search: data.value, isTelemed}
      });
    }
    setSearchDoctor(data.value)
  }

  const handleCheckedIsTelemed = (event:any, data: any) => {
    props.onEvent({
      message: "HandleDoctorList",
      params: {isTelemed: data.checked}
    });
    setIsTelemed(data.checked)
  }

  return (
    <div style={{ padding: "0px 3% 3% 3%" }}>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
        className="tab-custom"
      />
      <SwipeableViews
        ref={containerRef}
        index={+viewIndex}
        onChangeIndex={handleChangeIndex}
        slideClassName="cheack-view"
      >
        <div>
          {/* search */}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0px",
              }}
            >
              <div style={{ width: "90%" }}>
                <Input
                  icon="search"
                  iconPosition="left"
                  placeholder="ค้นหาแพทย์"
                  size="mini"
                  fluid={true}
                  value={searchDoctor}
                  onChange={handleDoctorSearchChange}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "95%",
              }}
            >
              <Checkbox style={{ margin: "0px 10px" }} checked={isTelemed} onChange={handleCheckedIsTelemed}/>
              <div style={{ color: COLOR.primary_font, fontWeight: "bold" }}>
                Telemed
              </div>
            </div>
          </div>
          {/* card */}
          {doctorList
            // ?.filter((item: any) => item?.full_name.includes(searchDoctor))
            ?.map((item: any, index: any) => {
              return (
                <div
                  style={{ margin: "15px 0px" }}
                  onClick={() => handleCreateAppointmentIH("doctor", item?.id)}
                >
                  <div className={classes.cadeBox}>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ width: "25%", margin: "5px" }}>
                        <Image
                          src={item?.image || personImg}
                          style={{ borderRadius: "10px" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "75%",
                          margin: "10px",
                        }}
                      >
                        <div
                          style={{
                            color: COLOR.primary_font,
                            fontWeight: "bold",
                            paddingBottom: "5px",
                          }}
                        >
                          {item?.full_name}
                        </div>
                        <div style={{ color: COLOR.grey }}>
                          {item?.divisions}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Icon className="hospital outline" color="grey" />
                          {item?.is_telemed && (<Icon className="video camera" color="grey" />)}
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div>
          {/* search */}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px 0px",
              }}
            >
              <div style={{ width: "90%" }}>
                <Input
                  icon="search"
                  iconPosition="left"
                  placeholder="ค้นหาแผนก"
                  size="mini"
                  fluid={true}
                  value={searchDivision}
                  onChange={(e: any, data: any) =>
                    setSearchDivision(data.value)
                  }
                />
              </div>
            </div>
          </div>
          {/* card */}
          {divisionList
            ?.filter((item: any) => item?.name.includes(searchDivision))
            ?.map((item: any, index: any) => {
              return (
                <div
                  style={{ margin: "15px 0px" }}
                  onClick={() =>
                    handleCreateAppointmentIH("division", item?.id)
                  }
                >
                  <div className={classes.cadeBox}>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Image
                          src={personImg}
                          circular
                          size="mini"
                          style={{ border: "1px solid rgba(227,239,255,0.5)" }}
                        />
                      </div>
                      <div
                        style={{
                          width: "80%",
                          margin: "10px",
                        }}
                      >
                        <div
                          style={{
                            color: COLOR.primary,
                            fontWeight: "bold",
                            paddingBottom: "5px",
                          }}
                        >
                          {item?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </SwipeableViews>
    </div>
  );
};

PatientSelectAppointmentIH.defaultProps = {};

export default React.memo(
  injectIntl(PatientSelectAppointmentIH, { forwardRef: true })
);
