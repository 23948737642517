import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Segment,
  Image,
  Form,
  Button,
  Input,
  Dropdown,
  Dimmer,
  Loader,
  Icon,
  TextArea,
  Checkbox,
  Grid,
  Modal,
  Header,
} from "semantic-ui-react";

import personImg from "react-lib/apps/common/person.png";
import DateTextBox from "react-lib/apps/common/DateTextBox";
import moment from "moment";
import * as Util from "react-lib/utils";
import { useCookies } from "react-cookie";
import { FormattedMessage, useIntl } from "react-intl";
import { formatADtoBEString } from "react-lib/utils/dateUtils";
import { bloodOptions, rhOptions, diseaseList, foodList } from "./ProfileWrap";

import { makeStyles, createStyles, withStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import MaterialButton from "@mui/material/Button";
import StepConnector from "@mui/material/StepConnector";

// const diseaseList = [
//   "ความดันโลหิต",
//   "เบาหวาน",
//   "โรคหัวใจ",
//   "โรคเกี่ยวกับสมอง",
//   "โรคปอด",
//   "Stroke",
//   "อื่นๆ",
// ];
// const foodList = [
//   "อาหารทะเล",
//   "ถั่วลิสง",
//   "ไข่",
//   "นม",
//   "แป้งสาลี",
//   "อื่นๆ",
// ];

const ProfileCreate = (props) => {
  // const AllgeryInit = useMemo( () => {

  //   let allergy = {}
  //   let list = diseaseList.map({
  //     return {

  //     }
  //   })
  //   return

  // }, [])

  const isMounted = useRef(true);

  const [birthday, setBirthday] = useState("");
  // const [chatChannel ,setChatChannel] = useState([])
  const [selectedChatChannel, setSelectedChatChannel] = useState(null);
  const [hospitalNumber, setHospitalNumber] = useState("");
  const [lockHN, setLockHN] = useState(false);
  const [proxyId, setProxyId] = useState("");
  const [userId, setUserId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [gender, setGender] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [cookies] = useCookies([]);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [personWeight, setPersonWeight] = useState("");
  const [personHeight, setPersonHeight] = useState("");
  const [address, setAddress] = useState("");
  const [congenital, setCongenital] = useState("");
  const [foodAllergy, setFoodAllergy] = useState("");
  const [drugAllergy, setDrugAllergy] = useState("");
  const [bloodType, setBloodType] = useState("");
  const [rhType, setRhType] = useState("");

  // const [openAllergy, setOpenAllergy] = useState(false);

  const [allergyText, setAllergyText] = useState("");
  const [noDrugAllergy, setNoDrugAllergy] = useState(false);
  const [noFoodAllergy, setNoFoodAllergy] = useState(false);
  const [noCongenital, setNoCongenital] = useState(false);
  const [allergyData, setAllergyData] = useState({});

  const [openRequired, setOpenRequired] = useState(false);
  const [requiredMessage, setRequiredMessage] = useState("");

  const intl = useIntl();
  const inputFile = useRef(null);
  const [image, setImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  // Donot update email
  const [email, setEmail] = useState("");
  // Ref for input
  // const firstNameRef = useRef();
  // const lastNameRef = useRef();
  // const phoneRef = useRef();

  // const weightRef = useRef();
  // const heightRef = useRef();

  useEffect(() => {
    if (props.profile) {
      let dob = "";
      if (props.profile?.dob) {
        dob = formatADtoBEString(props.profile?.dob);
      }
      setFirstname(props.profile?.first_name);
      setLastname(props.profile?.last_name);
      setPhoneNo(props.profile?.phone_no);
      setBirthday(dob);
      setEmail(props.profile?.email);
      setAddress(props.profile?.address);
      setUserId(props.profile?.user);
      setPatientId(props.profile?.patient)
      if (props.profile?.extra) {
        let extra = JSON.parse(props.profile?.extra);
        const {
          gender,
          personWeight,
          personHeight,
          bloodType,
          rhType,
          congenital,
          foodAllergy,
          drugAllergy,
        } = extra;
        setGender(gender === 1 ? "male" : gender === 2 ? "female" : "");
        setPersonWeight(personWeight ? personWeight : "");
        setPersonHeight(personHeight ? personHeight : "");
        setBloodType(bloodType ? bloodType : "");
        setRhType(rhType ? rhType : "");
        setCongenital(congenital ? congenital : "");
        setFoodAllergy(foodAllergy ? foodAllergy : "");
        setDrugAllergy(drugAllergy ? drugAllergy : "");
      }
    }
  }, [props.profile]);

  const loadProxyHasDivision = async (needLoading = true) => {
    let apiToken = props.apiToken ? props.apiToken : cookies.apiToken;
    if (needLoading) {
      setProfileLoading(true);
    }
    const [data, error, network] =
      await props.controller.getProxyPatientHasDivision({ apiToken: apiToken });

    // console.log(' loadProxyHasDivision', data)
    if (isMounted.current) {
      if (needLoading) {
        setProfileLoading(false);
      }
      if (data) {
        let items = data.items;

        // console.log("data loadProxyHasDivision", data);
        if (items && items.length > 0) {
          let prepare = items.find((item) => {
            return item.division.name === selectedChatChannel;
          });

          if (prepare) {
            // console.log("loadProxyHasDivision prepare", prepare);
            setProxyId(prepare && prepare.id ? prepare.id : null);
            setHospitalNumber(prepare.hn);
            setLockHN(prepare.lock_hn);
          }
        }
      }
    }
  };

  const handleDateChange = (date) => {
    setBirthday(date);
  };

  const handleOnHNChange = (e, data) => {
    if (!lockHN) {
      setHospitalNumber(data.value);
    }
  };

  const handleSelectHospitalList = (e, data) => {
    setSelectedChatChannel(data.value);
    loadProxyHasDivision();
  };

  useEffect(() => {
    loadProxyHasDivision(false);
  }, [selectedChatChannel]);

  useEffect(() => {
    if (!props.apiToken && !cookies.apiToken) {
      props.noApiToken();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  // console.log("props ProfileCreate", props);

  // We need refence inside this UX
  // const prepareSave = () => {
  //   console.log(" FirstName ", firstNameRef.current.inputRef.current.value);
  //   console.log(" LastName ", lastNameRef.current.inputRef.current.value);
  //   console.log(" TelephoneNo ", phoneRef.current.inputRef.current.value);
  //   let profile = {
  //     firstname: firstNameRef.current.inputRef.current.value,
  //     lastname: lastNameRef.current.inputRef.current.value,
  //     phone: phoneRef.current.inputRef.current.value,
  //     birthday: birthday,
  //   };
  //   let hospital = {
  //     hospitalNumber: hospitalNumber,
  //     proxyId: proxyId,
  //   };
  //   props.onSave(profile, hospital);
  // };

  const chatChannelToDropdownOption = (data) => {
    // console.log("Compute chatChannelToDropdownOption");
    let dataOption = [];
    if (data && data.length > 0) {
      dataOption = data.map((item) => {
        return { key: item.id, text: item.name, value: item.name };
      });
    }
    return dataOption;
  };

  const chatChannelOptions = useMemo(
    () => chatChannelToDropdownOption(props.chatChannelList),
    [props.chatChannelList]
  );

  useEffect(() => {
    if (
      chatChannelOptions &&
      Array.isArray(chatChannelOptions) &&
      chatChannelOptions.length > 0 &&
      !selectedChatChannel
    ) {
      // console.log("loadProxyHasDivision  SelectedDefault ");
      setSelectedChatChannel(chatChannelOptions[0].value);
    }
  }, [props.chatChannelList, chatChannelOptions]);

  const ButtonStepper = () => {
    return (
      <div className="step-div">
        {props.profileStepperIndex > 0 ? (
          <MaterialButton
            disabled={props.profileStepperIndex === 0}
            onClick={handleBack}
            className={classes.backButton}
          >
            Back
          </MaterialButton>
        ) : null}
        <MaterialButton
          variant="contained"
          color="secondary"
          onClick={handleNext}
        >
          {props.profileStepperIndex === steps.length - 1 ? (
            <FormattedMessage id="common.done" />
          ) : (
            <FormattedMessage id="common.next" />
          )}
        </MaterialButton>
      </div>
    );
  };

  // Require Field #2 Telphone

  const CreateContact = () => {
    return (
      <div className="profile">
        <Segment basic className="profile-segment-noimage">
          <Dimmer.Dimmable>
            <Dimmer active={profileLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <p>
              <Icon name="address book outline" />
              <FormattedMessage id="profile.contact" />
            </p>
            <Form className="profile-segment-form">
              <Form.Field>
                <label className="required-field">
                  <FormattedMessage id="profile.tel_no" />
                </label>
                <Input
                  fluid
                  value={phoneNo}
                  onChange={(e, data) => {
                    setPhoneNo(data.value);
                  }}
                />
              </Form.Field>

              <Form.Field>
                <label>
                  <FormattedMessage id="profile.email" />
                </label>
                <Input
                  fluid
                  value={email}
                  onChange={(e, data) => {
                    setEmail(data.value);
                  }}
                />
              </Form.Field>

              <Form.Field>
                <label>
                  <FormattedMessage id="profile.address" />
                </label>
                <TextArea
                  rows="7"
                  value={address}
                  onChange={(e, data) => {
                    setAddress(data.value);
                  }}
                />
              </Form.Field>
            </Form>
            {ButtonStepper()}
          </Dimmer.Dimmable>
        </Segment>
      </div>
    );
  };

  // const genOptions = (list) => {
  //   let options = list.map((item, idx) => {
  //     return { key: idx, value: item, text: item }
  //     })
  //   return options ? options : []
  // }

  const CreateAllergy = (allergyId) => {
    let list = [];
    let otherValue = null;
    let allValue = null;
    let optionsValue = null;
    let setAllValue = null;

    //profile.foodallergy", profile.congenitaldisease
    if (allergyText.includes("disease")) {
      // โรคประจำตัว
      list = diseaseList;

      let congenitalList = congenital
        ? congenital.split(",")?.map((item) => item.trim())
        : [];
      otherValue = congenitalList.filter((item) => !list.includes(item));
      optionsValue = congenitalList.filter((item) => list.includes(item));

      // allergyOtherValue = congenital;
      setAllValue = setCongenital;
      allValue = congenital;
    } else {
      // แพ้อาหาร
      list = foodList;

      let foodAllergylList = foodAllergy
        ? foodAllergy.split(",")?.map((item) => item.trim())
        : [];
      otherValue = foodAllergylList.filter((item) => !list.includes(item));
      optionsValue = foodAllergylList.filter((item) => list.includes(item));

      // allergyOtherValue = congenital;
      setAllValue = setFoodAllergy;
      allValue = foodAllergy;
    }

    return (
      <div className="profile">
        <Segment basic className="profile-segment-noimage">
          <Dimmer.Dimmable>
            <Dimmer active={profileLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <div className="allergy-result">
              <label> {intl.formatMessage({ id: allergyId })} </label>
              <Button
                color="teal"
                onClick={() => {
                  props.onSetOpenAllergy(false);
                }}
              >
                {intl.formatMessage({ id: "common.done" })}
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Form>
                <TextArea rows={7} value={allValue} />
              </Form>
            </div>

            <div className="allergy-result">
              <label> Suggestions </label>
            </div>

            <div className="allergy-checkbox">
              {list.map((item, idx) => {
                if (list?.length === idx + 1) {
                  return (
                    // Other Options
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={idx}
                    >
                      <Checkbox
                        checked={otherValue?.length > 0}
                        onClick={(e, data) => {
                          if (data.checked) {
                            // console.log("Checked", otherValue)
                            if (otherValue?.length === 0) {
                              otherValue = [" "];
                            }
                          } else {
                            // console.log("Un Checked")
                            otherValue = [];
                          }

                          let all = optionsValue.concat(otherValue);
                          let total = all.join(", ");
                          // console.log("total: ", total);
                          setAllValue(total);
                        }}
                      />{" "}
                      <span
                        style={{
                          color: "#333333",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          if (otherValue?.length === 0) {
                            // console.log("Checked", otherValue)
                            if (otherValue?.length === 0) {
                              otherValue = [" "];
                            }
                          } else {
                            // console.log("Un Checked")
                            otherValue = [];
                          }

                          let all = optionsValue.concat(otherValue);
                          let total = all.join(", ");
                          // console.log("total: ", total);
                          setAllValue(total);
                        }}
                      >
                        {item}
                      </span>
                    </div>
                  );
                } else {
                  return (
                    // Options
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      key={idx}
                    >
                      <Checkbox
                        id={item}
                        checked={optionsValue.includes(item)}
                        onClick={(e, data) => {
                          let id = e.target.id;
                          if (optionsValue.includes(id)) {
                            optionsValue = optionsValue.filter(
                              (opt) => opt !== id
                            );
                          } else {
                            optionsValue.push(id);
                          }
                          let total = optionsValue.concat(
                            otherValue ? otherValue : []
                          );
                          setAllValue(total.join(", "));
                        }}
                      />{" "}
                      <span
                        id={item}
                        style={{
                          color: "#333333",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                        onClick={(e) => {
                          let id = e.target.id;
                          if (optionsValue.includes(id)) {
                            optionsValue = optionsValue.filter(
                              (opt) => opt !== id
                            );
                          } else {
                            optionsValue.push(id);
                          }
                          let total = optionsValue.concat(
                            otherValue ? otherValue : []
                          );
                          setAllValue(total.join(", "));
                        }}
                      >
                        {item}
                      </span>
                    </div>
                  );
                }
              })}
            </div>
            <div style={{ marginTop: "10px" }}>
              <Form>
                <TextArea
                  disabled={otherValue?.length === 0}
                  {...(otherValue?.length === 0 && {
                    style: { backgroundColor: "#E0E0E0" },
                  })}
                  rows={7}
                  value={otherValue.join(",")}
                  onChange={(e, data) => {
                    let val = [data.value];
                    let all = optionsValue.concat(val);
                    let total = all.join(", ");
                    // console.log("total: ", total);
                    setAllValue(total);
                  }}
                />
              </Form>
            </div>
          </Dimmer.Dimmable>
        </Segment>
      </div>
    );
  };

  // Require Field #3 โรคประจำตัว, การแพ้อาหาร, การแพ้ยา

  const CreateMedical = () => {
    return (
      <div className="profile ">
        <Segment basic className="profile-segment-noimage">
          <Dimmer.Dimmable>
            <Dimmer active={profileLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <p>
              <Icon name="stethoscope" />{" "}
              <FormattedMessage id="profile.medical" />
            </p>
            <Form className="profile-segment-form">
              <div>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          {intl.formatMessage({ id: "profile.bloodtype" })}
                        </label>
                        <Dropdown
                          fluid
                          value={bloodType}
                          placeholder={intl.formatMessage({
                            id: "profile.bloodtype",
                          })}
                          selection
                          onChange={(e, { value }) => setBloodType(value)}
                          options={bloodOptions}
                        />
                      </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Field>
                        <label>
                          {intl.formatMessage({ id: "profile.rh" })}
                        </label>
                        <Dropdown
                          value={rhType}
                          fluid
                          placeholder={intl.formatMessage({
                            id: "profile.rh",
                          })}
                          selection
                          onChange={(e, { value }) => setRhType(value)}
                          options={rhOptions}
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>

              <Form.Field>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <label
                    style={{ marginRight: "10px", fontWeight: "bold" }}
                    className="required-field"
                  >
                    <FormattedMessage id="profile.congenitaldisease" />
                  </label>
                  <Checkbox
                    label={intl.formatMessage({ id: "common.none" })}
                    onChange={() => {
                      // console.log(" enabledCongenital", noCongenital);
                      setNoCongenital(!noCongenital);
                      setCongenital("");
                    }}
                    checked={noCongenital}
                  />
                </div>
                <TextArea
                  value={congenital}
                  disabled={noCongenital}
                  onClick={(e) => {
                    e.target.blur();
                    setAllergyText("profile.congenitaldisease");
                    props.onSetOpenAllergy(true);
                  }}
                  // onChange={(e, data) => {
                  //   setCongenital(data.value);
                  // }}
                />
              </Form.Field>
              <Form.Field>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <label
                    style={{ marginRight: "10px", fontWeight: "bold" }}
                    className="required-field"
                  >
                    <FormattedMessage id="profile.foodallergy" />
                  </label>
                  <Checkbox
                    label={intl.formatMessage({ id: "common.none" })}
                    onChange={() => {
                      setNoFoodAllergy(!noFoodAllergy);
                      setFoodAllergy("");
                    }}
                    checked={noFoodAllergy}
                  />
                </div>
                <TextArea
                  value={foodAllergy}
                  // onChange={(e, data) => {
                  //   setFoodAllergy(data.value);
                  // }}
                  disabled={noFoodAllergy}
                  onClick={(e) => {
                    e.target.blur();
                    setAllergyText("profile.foodallergy");
                    props.onSetOpenAllergy(true);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <div style={{ display: "flex", margin: "10px 0px" }}>
                  <label
                    style={{ marginRight: "10px", fontWeight: "bold" }}
                    className="required-field"
                  >
                    <FormattedMessage id="profile.drugallergy" />
                  </label>
                  <Checkbox
                    label={intl.formatMessage({ id: "common.none" })}
                    onChange={() => {
                      setNoDrugAllergy(!noDrugAllergy);
                      setDrugAllergy("");
                    }}
                    checked={noDrugAllergy}
                  />
                </div>
                <TextArea
                  disabled={noDrugAllergy}
                  value={drugAllergy}
                  onChange={(e, data) => {
                    setDrugAllergy(data.value);
                  }}
                />
              </Form.Field>
            </Form>
            {ButtonStepper()}
          </Dimmer.Dimmable>
        </Segment>
      </div>
    );
  };

  // Require Field #1 firstname, lastname, birthdate

  const CreateProfile = () => {
    // console.log(" props.profileData", props.profileData);

    //UploadImage
    const readChooseFile = (event) => {
      if (event.target.files && event.target.files[0]) {
        setProfileImage(event.target.files[0]);
        setImage(URL.createObjectURL(event.target.files[0]));
      }
    };

    return (
      <div className="profile minus-top">
        <div style={{ position: "relative" }}>
          <Image
            fluid
            circular
            centered
            src={
              image !== null
                ? image
                : props.profile && props.profile?.image
                ? props.profile.image
                : props.profile?.image_url
                ? props.profile.image_url
                : personImg
            }
            className={"profile-imageM border"}
          />
          <div
            className={"profile-image-radius"}
            onClick={() => {
              inputFile.current.click();
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                color: "white",
              }}
            >
              <FormattedMessage id="common.edit" />
            </div>
          </div>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={readChooseFile}
            accept="image/*"
          />
        </div>

        {/* <Image
          className={"profile-image border"}
          fluid
          circular
          centered
          src={
            props.profile && props.profile?.image 
              ? props.profile.image
              : props.profile?.image_url ? props.profile.image_url : personImg
          }

        /> */}

        <Segment basic className="profile-segment">
          <Dimmer.Dimmable>
            <Dimmer active={profileLoading} inverted>
              <Loader inverted>Loading...</Loader>
            </Dimmer>
            <p>
              <FormattedMessage id="profile.profile" />
            </p>
            <Form className="profile-segment-form">
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.ihid" />
                </label>
                <Input fluid value={patientId} />
              </Form.Field>
              <Form.Field>
                <label className="required-field">
                  <FormattedMessage id="profile.first_name" />
                </label>
                <Input
                  fluid
                  value={firstname}
                  onChange={(e, data) => {
                    setFirstname(data.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label className="required-field">
                  <FormattedMessage id="profile.last_name" />
                </label>
                <Input
                  fluid
                  value={lastname}
                  onChange={(e, data) => {
                    setLastname(data.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label className="required-field">
                  <FormattedMessage id="profile.day_month_year_birth" />
                </label>
                <DateTextBox
                  value={birthday}
                  onChange={handleDateChange}
                  keepFocus={false}
                  maxDate={Util.formatDate(moment())}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.gender" />
                </label>
                <div style={{ marginTop: "10px" }}>
                  {gender === "male" && (
                    <>
                      {" "}
                      <Icon name="male" color="teal" size="big" />{" "}
                      <FormattedMessage id="common.male" />{" "}
                    </>
                  )}
                  {gender === "female" && (
                    <>
                      {" "}
                      <Icon name="female" color="teal" size="big" />
                      <FormattedMessage id="common.female" />{" "}
                    </>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    {...(gender === "male" && { color: "teal" })}
                    onClick={() => {
                      setGender("male");
                    }}
                  >
                    <FormattedMessage id="common.male" />
                  </Button>
                  <Button
                    {...(gender === "female" && { color: "teal" })}
                    onClick={() => {
                      setGender("female");
                    }}
                  >
                    <FormattedMessage id="common.female" />
                  </Button>
                </div>
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.weight" />
                </label>
                <Input
                  fluid
                  value={personWeight}
                  onChange={(e, data) => {
                    setPersonWeight(data.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.height" />
                </label>
                <Input
                  fluid
                  value={personHeight}
                  onChange={(e, data) => {
                    setPersonHeight(data.value);
                  }}
                />
              </Form.Field>

              {/* <Form.Field>
                <label>
                  <FormattedMessage id="profile.tel_no" />
                </label>
                <Input fluid ref={phoneRef} />
              </Form.Field>
              <Form.Field>
                <label>
                  <FormattedMessage id="profile.email" />
                </label>
                <Input fluid value={email} />
              </Form.Field> */}
              {/* <Form.Field>
                <Form.Field required>
                  <label>
                    <FormattedMessage id="profile.hospital" />
                  </label>
                  <Dropdown
                    selection
                    fluid
                    placeholder="โปรดเลือกโรงพยาบาล เพื่อดูข้อมูล"
                    value={selectedChatChannel}
                    options={chatChannelOptions}
                    onChange={handleSelectHospitalList}
                  />
                </Form.Field>
                <Form.Field>
                  <label>HN.</label>
                  <Input
                    fluid
                    readOnly={lockHN ? "1" : null}
                    value={hospitalNumber}
                    onChange={handleOnHNChange}
                  />
                </Form.Field>
              </Form.Field> */}
            </Form>

            {ButtonStepper()}
            {/* <div className="save-div">
              <Button
                onClick={prepareSave}
                loading={isLoading}
                disabled={isLoading || !selectedChatChannel}
              >
                <FormattedMessage id="common.save" />
              </Button>
            </div> */}
          </Dimmer.Dimmable>
        </Segment>
      </div>
    );
  };

  /// Material UI
  // Step handler
  const theme = createTheme({});
  ///
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        width: "100%",
      },
      backButton: {
        marginRight: theme.spacing(1),
      },
      instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#06B3DB",
      },
      active: {}, //needed so that the &$active tag works
      completed: {},
      disabled: {},

      step: {
        "&$completed": {
          color: "#06B3DB",
        },
        "&$active": {
          color: "#06B3DB",
        },
        "&$disabled": {
          color: "red",
        },
      },
    })
  );

  const getSteps = () => {
    return [
      <FormattedMessage id="profile.profile" />,
      <FormattedMessage id="profile.contact" />,
      <FormattedMessage id="profile.medical" />,
    ];
  };

  // ต้องขออภัยในการใช้งานแบบนี้ไว้เลย งานเร่งงานเผา ต้องเรียนผ่านฟังค์แบบนี้ ไม่ได้แยกเป็น component
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return CreateProfile();
      case 1:
        return CreateContact();
      case 2:
        return CreateMedical();
      default:
        return "Unknown stepIndex";
    }
  };

  const classes = useStyles();
  // const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    active: {
      "& $line": {
        borderColor: "#06B3DB",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#06B3DB",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);

  // We need refence inside this UX
  const prepareSave = () => {
    let profile = {
      firstname: firstname,
      lastname: lastname,
      phone: phoneNo,
      birthday: birthday,
      gender: gender,
      personWeight: personWeight,
      personHeight: personHeight,
      address: address,
      bloodType: bloodType,
      rhType: rhType,
      congenital: congenital,
      foodAllergy: foodAllergy,
      drugAllergy: drugAllergy,
    };
    props.onSave(profile);

    props.onEvent({
      message: "HandleUpdateProfileImage",
      params: {
        image: profileImage,
      },
    });
  };

  const handleNext = () => {
    // console.log("handleSet !! ", props.profileStepperIndex);
    if (props.profileStepperIndex >= 2) {
      // Save
      // then goto ProfileInfo

      // check Require #3

      let msg = [];
      if (!congenital && !noCongenital) {
        msg.push(intl.formatMessage({ id: "profile.congenitaldisease" }));
      }

      if (!foodAllergy && !noFoodAllergy) {
        msg.push(intl.formatMessage({ id: "profile.foodallergy" }));
      }
      if (!drugAllergy && !noDrugAllergy) {
        msg.push(intl.formatMessage({ id: "profile.drugallergy" }));
      }

      if (msg?.length > 0) {
        // require field
        setOpenRequired(true);
        setRequiredMessage(msg.join(", "));
        return;
      }

      prepareSave();
    } else {
      if (props.profileStepperIndex === 0) {
        // check Require #1
        let msg = [];
        if (!firstname) {
          msg.push(intl.formatMessage({ id: "profile.first_name" }));
        }
        if (!lastname) {
          msg.push(intl.formatMessage({ id: "profile.last_name" }));
        }
        if (!birthday) {
          msg.push(intl.formatMessage({ id: "profile.day_month_year_birth" }));
        }

        if (msg?.length > 0) {
          // require field
          setOpenRequired(true);
          setRequiredMessage(msg.join(", "));
          return;
        }
      } else if (props.profileStepperIndex === 1) {
        // check Require #2
        let msg = [];
        if (!phoneNo || phoneNo?.length < 10) {
          msg.push(intl.formatMessage({ id: "profile.tel_no" }));
        }
        if (msg?.length > 0) {
          // require field
          setOpenRequired(true);
          setRequiredMessage(msg.join(", "));
          return;
        }
      }

      props.onChangeProfileStepper(props.profileStepperIndex + 1);

      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    props.onChangeProfileStepper(props.profileStepperIndex - 1);
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    props.onChangeProfileStepper(0);
    // setActiveStep(0);
  };

  // render
  return (
    <>
      <Modal open={openRequired}>
        <Modal.Header>
          {intl.formatMessage({ id: "profile.requiredTitle" })}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Header>
              {intl.formatMessage({ id: "profile.requiredGuide" })}
            </Header>
            <p>{requiredMessage}</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ justifyContent: "center", display: "flex" }}>
          <Button
            content={intl.formatMessage({ id: "common.ok" })}
            onClick={() => {
              setOpenRequired(false);
              setRequiredMessage("");
            }}
            positive
          />
        </Modal.Actions>
      </Modal>

      <div>
        {!props.openAllergyProfile && (
          <>
            <div className="profile-create wizard profile-title">
              <FormattedMessage id="profile.title" />
            </div>

            <Stepper
              activeStep={props.profileStepperIndex}
              connector={<QontoConnector />}
              alternativeLabel={true}
              style={{ padding: "24px" }}
            >
              {steps.map((label, idx) => (
                <Step key={idx}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        root: classes.step,
                        completed: classes.completed,
                        active: classes.active,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </>
        )}
      </div>

      {props.profileStepperIndex === steps.length ? (
        <div>
          <Typography className={classes.instructions}>
            All steps completed
          </Typography>
          <MaterialButton onClick={handleReset}>Reset</MaterialButton>
        </div>
      ) : null}

      {!props.openAllergyProfile && getStepContent(props.profileStepperIndex)}
      {props.openAllergyProfile && CreateAllergy(allergyText)}
    </>
  );
};

ProfileCreate.defaultProps = {
  controller: {},
  noApiToken: () => {},
  hideLogout: false,
  apiToken: "",
  handleGoBack: () => {},
  profile: null,
  onSave: () => {},
  chatChannelList: [],
  profileStepperIndex: 0,
  onChangeProfileStepper: () => {},
  openAllergyProfile: false,
  onSetOpenAllergy: () => {},
};

export default ProfileCreate;
