import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
  Component
} from "react";
// import Cookies from "js-cookie";
import moment from "moment";
import {
  Divider,
  Dropdown,
  Form,
  Icon,
  Dimmer,
  Loader,
  Segment,
  Header,
  Modal,
  Button,
  DropdownProps
} from "semantic-ui-react";
import SwipeableViews from "react-swipeable-views";
// import { toast } from "react-toastify";
import ErrorMessage from "../../common/ErrorMessage";
import { FormattedMessage, injectIntl, WrappedComponentProps } from "react-intl";
import VCAppointmentController from "./VCAppointmentController";
import QUEManager from "../../../apis/manager/QUEManager"
import PRXManager from "../../../apis/manager/PRXManager"

// Css
import "./VCAppointmentSlot.scss";

// Common

type VCAppointmentSlotProps = {
  apiToken: string;
  header: {},
  match: {
    params: {
      divisionId: string
    }
  },
  params: {
    divisionId: number,
    divisionName: string,
    year: number | string,
    month: string;
    appointmentId: number;
  }
  // Controller
  controller: VCAppointmentController & {
    queManager: QUEManager,
    prxManager: PRXManager
  }
} & WrappedComponentProps

type AllMonthType = {
  monthName: string,
  monthShortName: string,
  monthIndex: number,
  year: number
}

type ActionType = "POSTPONE" | "BOOKING"

type ModInfoType = {
  open: boolean,
  error?: any,
  data: any,
  type?: ActionType
}

type CreateSlotFormProps = {
  data: any[];
  appointmentId?: number;
  // Callback
  onSlotClick: (arg0: any, arg1: any) => void;
}

const modInfoInitial: ModInfoType = {
  open: false,
  error: null,
  data: {},
  type: "BOOKING"
};

const CLASSIC_BLUE = "#004884";

const currentYear = +moment().format("YYYY");

const MOCK_DATA = {
  "total": 160,
  "next": "http://localhost:8000/apis/PRX/available-vaccine-division-service-block/?limit=100&offset=100",
  "previous": null,
  "items": [
    {
      "id": 29817,
      "datetime": "2021-05-28T12:00:00+07:00",
      "date": "2021-05-28",
      "label": "12:00-12:15 น.",
      "booking_count": 0,
      "start_serial": 154388,
      "end_serial": 154389,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29831,
      "datetime": "2021-06-01T17:00:00+07:00",
      "date": "2021-06-01",
      "label": "17:00-18:00 น.",
      "booking_count": 0,
      "start_serial": 154792,
      "end_serial": 154796,
      "status": 1,
      "capacity": 8,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29810,
      "datetime": "2021-06-03T09:00:00+07:00",
      "date": "2021-06-03",
      "label": "09:00-09:15 น.",
      "booking_count": 0,
      "start_serial": 154952,
      "end_serial": 154953,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29812,
      "datetime": "2021-06-03T11:00:00+07:00",
      "date": "2021-06-03",
      "label": "11:00-11:15 น.",
      "booking_count": 0,
      "start_serial": 154960,
      "end_serial": 154961,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29832,
      "datetime": "2021-06-03T17:00:00+07:00",
      "date": "2021-06-03",
      "label": "17:00-18:00 น.",
      "booking_count": 0,
      "start_serial": 154984,
      "end_serial": 154988,
      "status": 1,
      "capacity": 8,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29814,
      "datetime": "2021-06-07T09:00:00+07:00",
      "date": "2021-06-07",
      "label": "09:00-09:15 น.",
      "booking_count": 0,
      "start_serial": 155336,
      "end_serial": 155337,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29813,
      "datetime": "2021-06-07T09:00:00+07:00",
      "date": "2021-06-07",
      "label": "09:00-09:15 น.",
      "booking_count": 0,
      "start_serial": 155336,
      "end_serial": 155337,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29815,
      "datetime": "2021-06-09T09:00:00+07:00",
      "date": "2021-06-09",
      "label": "09:00-09:15 น.",
      "booking_count": 0,
      "start_serial": 155528,
      "end_serial": 155529,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29816,
      "datetime": "2021-06-09T09:00:00+07:00",
      "date": "2021-06-09",
      "label": "09:00-09:15 น.",
      "booking_count": 0,
      "start_serial": 155528,
      "end_serial": 155529,
      "status": 1,
      "capacity": 1,
      "division": 13999,
      "provider": 119
    },
    {
      "id": 29767,
      "datetime": "2021-06-21T09:00:00+07:00",
      "date": "2021-06-21",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156680,
      "end_serial": 156692,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29768,
      "datetime": "2021-06-21T09:00:00+07:00",
      "date": "2021-06-21",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156680,
      "end_serial": 156692,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29769,
      "datetime": "2021-06-21T09:00:00+07:00",
      "date": "2021-06-21",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156680,
      "end_serial": 156692,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29799,
      "datetime": "2021-06-21T09:00:00+07:00",
      "date": "2021-06-21",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156680,
      "end_serial": 156692,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29770,
      "datetime": "2021-06-21T09:00:00+07:00",
      "date": "2021-06-21",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156680,
      "end_serial": 156692,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29771,
      "datetime": "2021-06-21T13:00:00+07:00",
      "date": "2021-06-21",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156696,
      "end_serial": 156708,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29772,
      "datetime": "2021-06-21T13:00:00+07:00",
      "date": "2021-06-21",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156696,
      "end_serial": 156708,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29773,
      "datetime": "2021-06-21T13:00:00+07:00",
      "date": "2021-06-21",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156696,
      "end_serial": 156708,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29803,
      "datetime": "2021-06-21T13:00:00+07:00",
      "date": "2021-06-21",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156696,
      "end_serial": 156708,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29774,
      "datetime": "2021-06-21T13:00:00+07:00",
      "date": "2021-06-21",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156696,
      "end_serial": 156708,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29800,
      "datetime": "2021-06-22T09:00:00+07:00",
      "date": "2021-06-22",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156776,
      "end_serial": 156788,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29804,
      "datetime": "2021-06-22T13:00:00+07:00",
      "date": "2021-06-22",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156792,
      "end_serial": 156804,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29784,
      "datetime": "2021-06-23T09:00:00+07:00",
      "date": "2021-06-23",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156872,
      "end_serial": 156884,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29785,
      "datetime": "2021-06-23T09:00:00+07:00",
      "date": "2021-06-23",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156872,
      "end_serial": 156884,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29801,
      "datetime": "2021-06-23T09:00:00+07:00",
      "date": "2021-06-23",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156872,
      "end_serial": 156884,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29786,
      "datetime": "2021-06-23T09:00:00+07:00",
      "date": "2021-06-23",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156872,
      "end_serial": 156884,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29783,
      "datetime": "2021-06-23T09:00:00+07:00",
      "date": "2021-06-23",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156872,
      "end_serial": 156884,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29789,
      "datetime": "2021-06-23T13:00:00+07:00",
      "date": "2021-06-23",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156888,
      "end_serial": 156900,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29805,
      "datetime": "2021-06-23T13:00:00+07:00",
      "date": "2021-06-23",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156888,
      "end_serial": 156900,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29790,
      "datetime": "2021-06-23T13:00:00+07:00",
      "date": "2021-06-23",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156888,
      "end_serial": 156900,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29787,
      "datetime": "2021-06-23T13:00:00+07:00",
      "date": "2021-06-23",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156888,
      "end_serial": 156900,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29788,
      "datetime": "2021-06-23T13:00:00+07:00",
      "date": "2021-06-23",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156888,
      "end_serial": 156900,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29791,
      "datetime": "2021-06-24T09:00:00+07:00",
      "date": "2021-06-24",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156968,
      "end_serial": 156980,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29802,
      "datetime": "2021-06-24T09:00:00+07:00",
      "date": "2021-06-24",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156968,
      "end_serial": 156980,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29792,
      "datetime": "2021-06-24T09:00:00+07:00",
      "date": "2021-06-24",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156968,
      "end_serial": 156980,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29793,
      "datetime": "2021-06-24T09:00:00+07:00",
      "date": "2021-06-24",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156968,
      "end_serial": 156980,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29794,
      "datetime": "2021-06-24T09:00:00+07:00",
      "date": "2021-06-24",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 156968,
      "end_serial": 156980,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29795,
      "datetime": "2021-06-24T13:00:00+07:00",
      "date": "2021-06-24",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156984,
      "end_serial": 156996,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29806,
      "datetime": "2021-06-24T13:00:00+07:00",
      "date": "2021-06-24",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156984,
      "end_serial": 156996,
      "status": 1,
      "capacity": 60,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29796,
      "datetime": "2021-06-24T13:00:00+07:00",
      "date": "2021-06-24",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156984,
      "end_serial": 156996,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29797,
      "datetime": "2021-06-24T13:00:00+07:00",
      "date": "2021-06-24",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156984,
      "end_serial": 156996,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29798,
      "datetime": "2021-06-24T13:00:00+07:00",
      "date": "2021-06-24",
      "label": "13:00-16:00 น.",
      "booking_count": 0,
      "start_serial": 156984,
      "end_serial": 156996,
      "status": 1,
      "capacity": 120,
      "division": 13998,
      "provider": 117
    },
    {
      "id": 29841,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211816,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29961,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211808,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29956,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211808,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29951,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211808,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29842,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211816,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29946,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211800,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29941,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211800,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29843,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211816,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29931,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211800,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29844,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211816,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29921,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211808,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29845,
      "datetime": "2023-01-16T12:00:00+07:00",
      "date": "2023-01-16",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211796,
      "end_serial": 211816,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29936,
      "datetime": "2023-01-16T14:00:00+07:00",
      "date": "2023-01-16",
      "label": "14:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211804,
      "end_serial": 211808,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29846,
      "datetime": "2023-01-17T09:00:00+07:00",
      "date": "2023-01-17",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211880,
      "end_serial": 211892,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29847,
      "datetime": "2023-01-17T09:00:00+07:00",
      "date": "2023-01-17",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211880,
      "end_serial": 211892,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29848,
      "datetime": "2023-01-17T09:00:00+07:00",
      "date": "2023-01-17",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211880,
      "end_serial": 211892,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29849,
      "datetime": "2023-01-17T09:00:00+07:00",
      "date": "2023-01-17",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211880,
      "end_serial": 211892,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29850,
      "datetime": "2023-01-17T09:00:00+07:00",
      "date": "2023-01-17",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211880,
      "end_serial": 211892,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29851,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211912,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29922,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29897,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29917,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29852,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211912,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29892,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29912,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29962,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29853,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211912,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29957,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29952,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29947,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211896,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29854,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211912,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29887,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29907,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29942,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211896,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29932,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211896,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29855,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211912,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29902,
      "datetime": "2023-01-17T12:00:00+07:00",
      "date": "2023-01-17",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211892,
      "end_serial": 211904,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29927,
      "datetime": "2023-01-17T13:00:00+07:00",
      "date": "2023-01-17",
      "label": "13:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211896,
      "end_serial": 211904,
      "status": 1,
      "capacity": 120,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29937,
      "datetime": "2023-01-17T14:00:00+07:00",
      "date": "2023-01-17",
      "label": "14:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211900,
      "end_serial": 211904,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29856,
      "datetime": "2023-01-18T09:00:00+07:00",
      "date": "2023-01-18",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211976,
      "end_serial": 211988,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29857,
      "datetime": "2023-01-18T09:00:00+07:00",
      "date": "2023-01-18",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211976,
      "end_serial": 211988,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29858,
      "datetime": "2023-01-18T09:00:00+07:00",
      "date": "2023-01-18",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211976,
      "end_serial": 211988,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29859,
      "datetime": "2023-01-18T09:00:00+07:00",
      "date": "2023-01-18",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211976,
      "end_serial": 211988,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29860,
      "datetime": "2023-01-18T09:00:00+07:00",
      "date": "2023-01-18",
      "label": "09:00-12:00 น.",
      "booking_count": 0,
      "start_serial": 211976,
      "end_serial": 211988,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29903,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29862,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212008,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29923,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29863,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212008,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29898,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29918,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29864,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212008,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29893,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29913,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29963,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29958,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29865,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-17:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212008,
      "status": 1,
      "capacity": 300,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29953,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29948,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-13:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 211992,
      "status": 1,
      "capacity": 60,
      "division": 11092,
      "provider": 122
    },
    {
      "id": 29888,
      "datetime": "2023-01-18T12:00:00+07:00",
      "date": "2023-01-18",
      "label": "12:00-15:00 น.",
      "booking_count": 0,
      "start_serial": 211988,
      "end_serial": 212000,
      "status": 1,
      "capacity": 180,
      "division": 11092,
      "provider": 122
    }
  ]
}

const genAllMonthOfYear = (year: number = currentYear): AllMonthType[] => {
  return Array.apply(0, Array(12)).map((_: any, i: number) => {
    const month = moment().month(i);
    return {
      monthName: month.format("MMMM"),
      monthShortName: month.format("MMM"),
      monthIndex: +month.format("M") - 1,
      year: +year + 543
    };
  });
};

const CreateSlotForm = (props: CreateSlotFormProps) => {
  return <div className='slot-list'>

    {
      props.data.map((data: any, index: number) =>
        <div key={"list" + index}>
          <div style={{ display: "grid", gridTemplateColumns: "33px auto" }} >
            <div className='title'>
              <label >{moment(data.datetime).format("dd")}</label>
              <div style={moment(data.datetime).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD") ? { backgroundColor: "unset", color: CLASSIC_BLUE } : {}}>
                {moment(data.datetime).format("DD")}
              </div>
            </div>

            <div style={{ paddingTop: "4px", paddingLeft: "8px" }}>
              {
                data.items?.map((item: any, slotIndex: number) =>
                  <div
                    key={"slot" + index + slotIndex}
                    className={`slot-item${item.selected === true ? " booked" : ""}`}
                    onClick={() => {
                      props.onSlotClick(data, item);
                    }}
                  >
                    <div className='time-label'>
                      <div>{item.label}</div>
                      {item.selected === true && <div >
                        <Icon name='syringe' /> จอง
                      </div>}
                    </div>
                    <Form className='queue-progress'>
                      <div style={{ width: `${(item.capacity - item.booking_count) / item.capacity * 100}%` }}>

                      </div>

                      <div className='display-percent'>
                        <label>คิวว่าง</label>
                        <label>{item.booking_count}/{item.capacity}</label>
                      </div>
                    </Form>
                  </div>
                )
              }
            </div>
          </div>
          <Divider style={{ borderBottom: "0.5px solid rgba(38, 124, 173,0.5)", borderTop: 0 }} />
        </div>
      )
    }
  </div>;
};




const VCAppointmentSlot = (props: VCAppointmentSlotProps) => {
  const isMounted = useRef(true);
  // const [filterMonth, setFilterMonth] = useState<any>("")
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDivision, setIsLoadingDivision] = useState(false)

  // Open
  const [openModBookingConfirm, setOpenModBookingConfirm] = useState(modInfoInitial);

  const [viewIndex, setViewIndex] = useState<any>();
  const [activeYearList, setActiveYearList] = useState<any[]>([]);
  const [actionType, setActionType] = useState<ActionType>("BOOKING");
  const [overflowDropdown, setOverflowDropdown] = useState("hidden")

  // List
  const [generateMonth, setGenerateMonth] = useState<any[]>([]);

  // Detail
  const [divisionProfile, setDivisionProfile] = useState<any>({})

  // Ref
  const spanRef = useRef<HTMLSpanElement>();
  const dropdownMonthRef = useRef<Component<DropdownProps, any, any>>();
  const swipeableRef = useRef<any>()

  const [error, setError] = useState(null)

  const getSlotList = useCallback(
    async (params = {}) => {
      const { year, month } = params;

      // console.log(generateMonth, year, month)
      const checkData = generateMonth.findIndex(item => item.monthIndex === month && item.year === year && item.data);

      if (checkData >= 0) {
        swipeableRef.current.rootNode.scrollTop = generateMonth[checkData]?.scrollTop || 0
        return;
      }

      setIsLoading(true)
      let scrollTop = 0
      if (swipeableRef.current) {
        scrollTop = swipeableRef.current.rootNode.scrollTop
        swipeableRef.current.rootNode.scrollTop = 0
      }
      const [response, error] = await props.controller.prxManager.getAvailableVaccineDivision({
        apiToken: props.apiToken,
        division: props?.params?.divisionId,
        year_month: `${year - 543}-${month + 1}`,
      });

      // const vaccineItems = response?.items[0] ? response?.items : MOCK_DATA.items
      var vaccineItems = response?.items || []

      vaccineItems = vaccineItems.filter(item => moment(item.datetime).diff(moment()) > 0 )

      setIsLoading(false);

      const groupSlotByDate = (data: any[]) => {
        const hasx = new Map();

        return data.reduce((result, item) => {
          if (!hasx.has(item.date)) {
            result.push({
              datetime: item.datetime,
              items: [item]
            });
            hasx.set(item.date, hasx.size);
          } else {
            result[hasx.get(item.date)].items.push(item)
          }
          return result;
        }, []);
      }
      setGenerateMonth(prevData => prevData.map((item, index) => item.monthIndex === month && item.year === year
        ? { ...item, data: groupSlotByDate(vaccineItems) } : viewIndex === index
          ? { ...item, scrollTop } : item
      ))

    },
    [generateMonth],
  );

  useEffect(() => {

    if (props.params?.appointmentId) {
      setActionType("POSTPONE")
    }

    if (props.params?.year && props.params?.year !== "Invalid date" && props.params?.month && props.params?.month !== "Invalid date" && props.params?.divisionId) {

      const monthList = genAllMonthOfYear(+props.params?.year)
      const year = +props.params?.year + 543
      const month = +props.params?.month - 1
      const activeList = [year]

      setGenerateMonth(monthList);
      setActiveYearList(activeList);

      if (month === 11) {
        handleChangeYear(year + 1, -11, { monthList, activeList, view: 10 });
        return;
      }
      if (month === 0) {
        handleChangeYear(year - 1, 11, { monthList, activeList, view: 1 });
        return;
      }

      getSlotList({
        year: year,
        month: month
      });
      setViewIndex(month);
    }

    if (!props?.params?.month || props?.params?.month === "Invalid date" && props.params?.divisionId) {
      const currentMonth = +moment().format("MM") - 1
      setGenerateMonth([...genAllMonthOfYear()]);

      getSlotList({
        year: currentYear + 543,
        month: currentMonth
      });

      setViewIndex(currentMonth);
      setActiveYearList([currentYear + 543])
    }
  }, [props.params]);

  // Memo
  const monthOptions = useMemo(() => {
    return genAllMonthOfYear().map((month, index) => ({ key: index, value: index, text: month.monthShortName }));
  }, []);

  const yearOptions = useMemo(() => {
    const range = (start: number, end: number) =>
      Array.from({ length: end - start }, (v, k) => k + start);

    return range(1970, 3000).map((year, index) => ({ key: index, value: year, text: year }));
  }, []);

  // Handle
  const handleCloseModConfirmBooking = (error = null) => {

    if (error) {
      setError(error)
    } else {
      setError(null)
      setOpenModBookingConfirm(modInfoInitial);
    }
  };

  const handleSwitching = (index: number, type: string) => {
    if (type === "move" && spanRef.current && dropdownMonthRef.current) {
      const distance = Math.abs(index - viewIndex);
      const target = distance + 0.2;
      const next = 1 - distance + 0.3;
      const margin = 20 - (+distance.toFixed(2).split(".")[1] / 100 * 20);

      spanRef.current.style.opacity = `${target}`;

      const text: HTMLElement = dropdownMonthRef.current.ref.current.querySelector(".text");
      let indexMonth = viewIndex;

      if (text) {
        text.style.opacity = `${next}`;
      }

      if (index > viewIndex) {
        spanRef.current.style.right = `${-margin + 5}px`;
        spanRef.current.style.left = null;
        indexMonth = viewIndex + Math.ceil(distance);
      }
      else {
        spanRef.current.style.left = `-${margin}px`;
        spanRef.current.style.right = null;
        indexMonth = viewIndex - Math.ceil(distance);
      }

      spanRef.current.innerText = generateMonth[indexMonth].monthShortName;

    } else {
      spanRef.current.innerText = "";
      const text: HTMLElement = dropdownMonthRef.current.ref.current.querySelector(".text");
      if (text) {
        text.style.opacity = `${1}`;
      }
    }
  };

  // Handle Change
  const handleChangeIndex = async (index: number) => {

    if (Math.abs(index - viewIndex) === 2) {
      index += 1;
    }

    const currentMonth = generateMonth[index];

    if (currentMonth?.monthIndex === 11 && !activeYearList.includes(currentMonth?.year + 1)) {
      handleChangeYear(currentMonth?.year + 1, -11);
      return;
    }
    if (currentMonth?.monthIndex === 0 && !activeYearList.includes(currentMonth?.year - 1)) {
      handleChangeYear(currentMonth?.year - 1, 11);
      return;
    }

    getSlotList({
      year: currentMonth.year,
      month: currentMonth.monthIndex
    });
    setViewIndex(index);
  };

  const handleChangeMonth = (value: string | number | boolean | (string | number | boolean)[]) => {
    const currentMonth = generateMonth[viewIndex];
    const findViewIndex = generateMonth.findIndex(item => item.monthIndex === value && currentMonth.year === item.year);

    if (findViewIndex !== -1) {
      getSlotList({
        year: generateMonth[findViewIndex].year,
        month: generateMonth[findViewIndex].monthIndex
      });
    }
    setViewIndex(findViewIndex);
  };

  const handleChangeYear = (value: number, indexSubtract = 0, setDefault: any = {}) => {
    let { monthList = null, activeList = null, view = null } = setDefault
    activeList = activeList || activeYearList
    monthList = monthList || generateMonth
    view = view || viewIndex

    if (activeList.includes(value)) {
      const index = monthList.findIndex(list => list.year === value && list.monthIndex === view % 12);
      getSlotList({
        year: monthList[index].year,
        month: monthList[index].monthIndex
      });
      setViewIndex(index);
      return;
    }

    setIsLoading(true);


    const yearList = [...activeList, value].sort((a, b) => a - b);

    let cloneMonthList: any = [...monthList, ...genAllMonthOfYear(value - 543)].sort((a, b) => a.year - b.year);
    // yearList.map((list) => genAllMonthOfYear(list - 543))
    // cloneMonthList = cloneMonthList.flat()

    setGenerateMonth(cloneMonthList);
    let index = cloneMonthList.findIndex((list: { year: any; monthIndex: number; }) => list.year === value && list.monthIndex === view % 12);

    if (index !== -1) {
      index += indexSubtract;
    }

    getSlotList({
      year: cloneMonthList[index].year,
      month: cloneMonthList[index].monthIndex
    });

    setViewIndex(index);
    setActiveYearList(yearList);
  };

  // Handle Click
  const handleSlotClick = (data: { date: moment.MomentInput; }, children: { label: string; id: number }) => {
    console.log(data)
    if (actionType === "BOOKING") {
      setOpenModBookingConfirm({
        open: true,
        data: {
          title: "ยืนยันการจองคิววัคซีน COVID-19",
          date: `วันที่ ${moment(data.datetime).format("DD MMMM YYYY")}`,
          moment: `ช่วงเวลา ${children.label}`,
          id: children.id
        }
      });

    } else if (actionType === "POSTPONE") {
      setOpenModBookingConfirm({
        open: true,
        data: {
          title: "ยืนยันการเลื่อนคิววัคซีน COVID-19",
          date: `วันที่ ${moment(data.datetime).format("DD MMMM YYYY")}`,
          moment: `ช่วงเวลา ${children.label}`,
          description: "ระบบจะยกเลิกคิวนัดเดิม แล้วเปลี่ยนเป็นคิวนัดใหม่",
          id: children.id
        }
      });
    }
  };

  const handleConfirmBooking = async () => {
    if (actionType === "BOOKING") {
      const [response, error] = await props.controller.prxManager.postVaccineServiceBlock({
        apiToken: props.apiToken,
        slot: openModBookingConfirm.data?.id
      });
      console.log(response);
      if (response != null) {
        if (response.published_diag_rule_url != "") {
          props.goToDiagRule({
            diag_url: response.published_diag_rule_url
          })
        }
        
      }
    } else if (actionType === "POSTPONE") {
      const [response, error] = await props.controller.prxManager.putVaccineServiceBlock({
        apiToken: props.apiToken,
        slot: openModBookingConfirm.data?.id
      });
      console.log(response);
      // props.goToVaccineAppointmentView()
      window.history.back();
    }

    handleCloseModConfirmBooking(error)

  }

  console.log(props, actionType)

  return (
    <div className='vc-appointment-slot'>

      {props.header}
      <div >

        <div className='filter-header'>
          <Dimmer active={props?.params?.divisionId ? false : true} inverted style={{
            backgroundColor: "white",
            position: "absolute",
            height: "100vh",
          }}>
            <div style={{
              fontSize: "24px",
              color: "black"
            }}>
              {!isLoadingDivision && "No division"}
            </div>
          </Dimmer>
          <div style={{ display: "flex" }}>
            <Form
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                overflow: overflowDropdown,
                marginRight: "5px"
              }}

            >
              <span ref={spanRef} style={{ position: "absolute", zIndex: 1 }}></span>
              <Dropdown
                onClick={() => {
                  setOverflowDropdown("")
                }}
                onBlur={() => {
                  setOverflowDropdown("hidden")

                }}
                ref={dropdownMonthRef}
                selection
                options={monthOptions}
                value={viewIndex % 12}
                onChange={(e, { value }) => handleChangeMonth(value)}
                fluid
                style={{ width: "100%" }}
              />
            </Form>
            <Dropdown
              selection
              options={yearOptions}
              value={generateMonth[viewIndex]?.year}
              onChange={(e, { value }) => handleChangeYear(+value)}
              fluid
            />
          </div>
          <div style={{ justifyContent: "flex-end", display: "flex", color: CLASSIC_BLUE, fontSize: "16px", paddingLeft: "10px" }}>
            <Icon name='hospital' style={{ color: CLASSIC_BLUE }} />
            {props.params?.divisionName}
          </div>
        </div>


        <Segment style={{ boxShadow: "none", border: 0, padding: 0, height: "calc(100vh - 100px)" }}>
          <Dimmer active={isLoading} inverted>
            <Loader inverted>Loading</Loader>
          </Dimmer>
          <SwipeableViews
            ref={swipeableRef}
            animateTransitions={viewIndex % 12 === 0 ? false : true}
            index={viewIndex}
            onChangeIndex={handleChangeIndex}
            onSwitching={handleSwitching}
            style={{ height: "100%", marginTop: "60px", width: "100vw" }}>
            {
              generateMonth.map((month, index) =>
                <div key={"swipeable" + index}
                >
                  {
                    month.data?.[0] ?
                      <CreateSlotForm
                        data={month.data || []}
                        onSlotClick={handleSlotClick}
                        appointmentId={props.params?.appointmentId}
                      /> :
                      month.data ?
                        <div
                          style={{ marginTop: "35px", display: "flex", justifyContent: "center" }}>
                          <div style={{ background: "#f6f7f7", padding: "13px" }}>
                            ไม่พบข้อมูล
                          </div>
                        </div> : null
                  }
                </div>
              )
            }
          </SwipeableViews>
        </Segment>

      </div>

      <Modal
        open={openModBookingConfirm.open}
        closeOnDimmerClick={true}
        onClose={() => handleCloseModConfirmBooking()}
      >
        <Segment padded align='center' style={{
          border: 0,
          boxShadow: "none"
        }}>
          <Header style={{ color: CLASSIC_BLUE, marginTop: "5px", marginBottom: "20px" }}>
            {openModBookingConfirm.data?.title}
          </Header>

          <div style={{ display: "grid", rowGap: "12px", fontSize: "18px", margin: "30px 0px 5px" }}>
            <label>
              {openModBookingConfirm.data?.date}
            </label>
            <label>
              {openModBookingConfirm.data?.moment}
            </label>
          </div>
        </Segment>
        {
          openModBookingConfirm.data?.description &&
          <div
            style={{
              color: CLASSIC_BLUE,
              display: "flex",
              width: "100%",
              justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "15px",
              marginTop: "-5px"
            }}>
            {openModBookingConfirm.data?.description}
          </div>
        }
        <Form error={error ? true : false}>
          <ErrorMessage error={error} />
        </Form>
        <Button
          fluid
          style={{ backgroundColor: "#01b2ac", color: "white", fontSize: "18px" }}
          onClick={() => handleConfirmBooking()}
        >
          {"ยืนยัน"}
        </Button>
      </Modal>

    </div >
  );
};

export default React.memo(injectIntl(VCAppointmentSlot));
