import React from "react";
import PropTypes from "prop-types";
import * as Common from "../common/";
import { Form, Modal, Grid, Segment, Button } from "semantic-ui-react";
import { SearchBox } from "../common/";
import PureReactTable from "./PureReactTable";
import { vcMessenger } from "../../compat/vc-websocket";

// Config
import config from "../../../config/config";

// Const c.
const HOST = `${config.API_HOST}`;

const CardPrintList = React.forwardRef((props: any, ref) => {
  const [taskTracking, setTaskTracking] = React.useState(null);
  const [openModEncounterList, setOpenModEncounterList] = React.useState(false);
  const [encounterId, setEncounterId] = React.useState(null);
  const [encounterList, setEncounterList] = React.useState([]); //* as default options for SearchBox
  const [documentType, setDocumentType] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [file, setFile] = React.useState(null); //* pdf
  const [message, setMessage] = React.useState<any>({}); //* msg from vcMessenger

  //* show error and success
  const [openModInfo, setOpenModInfo] = React.useState(false);
  const [modInfoTitle, setModInfoTitle] = React.useState("");
  const [modInfoTitleColor, setModInfoTitleColor] = React.useState("");
  const [modInfoText, setModInfoText] = React.useState("");

  //* Mod Show task progress
  const [openModInfoTask, setOpenModInfoTask] = React.useState(false);
  const [modInfoTaskText, setModInfoTaskText] = React.useState("");

  //* Don't know what this is
  const [getPrinted, setGetPrinted] = React.useState(false);

  //* Cant find use case yet
  const [openModConfirm, setOpenModConfirm] = React.useState(false);
  const [modConfirmTitle, setModConfirmTitle] = React.useState("แจ้งเตือน!!!");
  const [reasonChoices, setReasonChoices] = React.useState([]);
  const [modConfirmError, setModConfirmError] = React.useState(null);

  const isMounted = React.useRef(false);
  const sbxEncounterRef = React.useRef();
  const reasonRef = React.useRef();

  React.useEffect(() => {
    isMounted.current = true;
    getDocumentType();
    vcMessenger.onMessage((msg: any) => {
      try {
        setMessage(msg);
      } catch (e) {}
    });
    getReason();
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (message.event === "DPI_DOCTOR_ORDER_PRINT") {
      if (message.task_id === taskTracking) {
        handleTaskTrackingStatus({ data: message });
      }
    }
  }, [message]);

  React.useEffect(() => {
    if (props.encounterId) {
      setEncounterId(props.encounterId);
    }
  }, [props.encounterId]);

  React.useEffect(() => {
    if (props.encounterNumber) {
      handleSearchEncounter({ searchText: props.encounterNumber });
    }
  }, [props.encounterNumber]);

  React.useEffect(() => {
    if (taskTracking) {
      var task = setInterval(() => {
        getTaskTracking();
      }, 15000);
    }
    return () => clearInterval(task);
  }, [taskTracking]);

  const clear = () => {
    setFile(null);
    setSelectedRow(null);
    getDocumentType();
    setTaskTracking(null);
    setGetPrinted(false);
    // frmError.clear()
  };

  const getReason = async () => {
    const [data, error] = await props.controller.getClinicalTerm({
      type: "REASON_PATIENT_CARD"
    });
    if (isMounted.current) {
      if (data) {
        setReasonChoices(data);
      }
    }
  };

  const handleTaskTrackingStatus = ({ data }: any = {}) => {
    if (data) {
      if (data.status === TASK_TRACKING_STATUS.COMPLETED && !getPrinted) {
        setTaskTracking(null);
        getPrintedDocument();
        setGetPrinted(true);
        setOpenModInfoTask(false);
      } else if (data.status === TASK_TRACKING_STATUS.FAILED) {
        setTaskTracking({ taskId: data.task_id } as any);
        setModInfoTaskText(data.task_id);
        setModInfoTitle("ผิดพลาด");
        setModInfoTitleColor("red");
      } else if (data.status === TASK_TRACKING_STATUS.CANCELED) {
        setTaskTracking(null);
        setModInfoText(data.current_progress_description);
        setModInfoTitle("ยกเลิก");
        setModInfoTitleColor("red");
        setOpenModInfoTask(false);
        setOpenModInfo(true);
      } else if (data.status === TASK_TRACKING_STATUS.IN_PROGRESS) {
        setModInfoTaskText(
          <div style={{ textAlign: "left", padding: 0 }}>
            <div>กรุณารอสักครู่ </div>
            <div>
              Status:{" "}
              {`${data.status} ${
                data.current_progress ? data.current_progress : data.progress
              }%`}
            </div>
            For IT: task_id = {data.task_id}
          </div> as any
        );
      } else if (!data.hasOwnProperty("status")) {
        setTaskTracking(null);
        handleGetDocumentResult({ data, preview: true });
      }
    }
  };

  const getPrintedDocument = ({ taskId }: any = {}) => {
    (reasonRef as any).current.clear();
    getTaskTracking({ taskId } as any);
  };

  const getTaskTracking = async ({ action, taskId }: any = {}) => {
    const [data, error] = await props.controller.getTaskTrackingResult({
      taskId: taskId ? taskId : taskTracking,
      action
    });
    if (isMounted.current) {
      handleTaskTrackingStatus({ data });
    }
  };

  const getDocumentType = async () => {
    const [data, error] = await props.controller.getDocumentType({
      printable: true,
      division: "current",
      offset: 0,
      limit: 40
    });
    if (isMounted.current) {
      if (data) {
        setDocumentType(data.items);
      }
    }
  };

  const handleSearchEncounter = async ({ searchText }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearch({
      patientId: props.patientId,
      search: searchText
    });
    if (isMounted.current) {
      if (data) {
        setEncounterList(data.items);
      }
    }
    return [data, error];
  };

  const handleSelectOption = async ({ item }: any = {}) => {
    getEncounterSearchDetail({ encounterId: item.id });
  };

  const getEncounterSearchDetail = async ({ encounterId }: any = {}) => {
    const [data, error] = await props.controller.getEncounterSearchDetail({
      encounterId
    });
    if (isMounted.current) {
      if (data) {
        setEncounterId(data.id);
      }
    }
  };

  const handleSelectDocType = (originalRow: any) => {
    setSelectedRow(originalRow);
    getPrintAPI({ originalRow, preview: true } as any);
  };

  const handlePrintAPIFail = ({ error, preview, confirm }: any = {}) => {
    if (preview) {
      clear();
    }
    setFile(null);
    if (confirm) {
      if (error) {
        setModConfirmTitle(error.message);
      }
      setOpenModConfirm(true);
    } else {
      setModInfoTitle("ผิดพลาด");
      setModInfoTitleColor("red");
      if (error) {
        if (error.message) {
          setModInfoText(error.message);
        } else {
          setModInfoText(error);
        }
      }
      setOpenModInfo(true);
    }
  };

  const getPrintAPI = async ({ originalRow, preview, confirm }: any = {}) => {
    setFile(null);
    if (!originalRow) {
      return;
    }
    setIsLoading(true);
    const [data, error, network] = await props.controller.getPrintAPI({
      printAPI: HOST + originalRow.print_api,
      patientId: props.patientId,
      orderId: props.operatingOrderId,
      encounterId,
      jasperModule: originalRow.jasper_module,
      jasperPath: originalRow.jasper_path,
      pdf: preview,
      confirm,
      reason: reasonRef.current ? (reasonRef as any).current.getValue() : "",
    });
    if (isMounted.current) {
      setIsLoading(false);
      if (data) {
        handleGetDocumentResult({ data, preview });
      } else if (error && ![500, 503].includes(network.response.status)) {
        handlePrintAPIFail({ error, preview, confirm });
      }
    }
  };

  const handleGetDocumentResult = ({ data, preview }: any = {}) => {
    if (preview && data.success && data.pdf_b64data.length > 0) {
      setOpenModInfoTask(false);
      return setFile(("data:application/pdf;base64," + data.pdf_b64data) as any);
    } else if (!preview && data.success) {
      setOpenModInfoTask(false);
      setModInfoText("พิมพ์รายการสำเร็จ");
      setModInfoTitle("สำเร็จ");
      setModInfoTitleColor("teal");
      setOpenModInfo(true);
    } else if (data.warning) {
      setFile("about:blank" as any);
      alert(data.warning);
    } else if (data.task_id) {
      setModInfoTaskText(
        <div style={{ textAlign: "left", padding: 0 }}>
          <div>กรุณารอสักครู่ </div>For IT: task_id = {data.task_id}
        </div> as any
      );
      setOpenModInfoTask(true);
      setGetPrinted(false);
      setTaskTracking(data.task_id);
    } else {
      setOpenModInfoTask(false);
      handlePrintAPIFail({ error: data.error, preview });
    }
  };

  const handleCancelTask = () => {
    getTaskTracking({ action: "CANCEL" });
  };

  const handleApproveConfirm = () => {
    setModConfirmError(null);
    if (!(reasonRef as any).current.getValue()) {
      return setModConfirmError("กรุณาระบุเหตุผล" as any);
    }
    setOpenModConfirm(false);
    getPrintAPI({ originalRow: selectedRow, preview: false, confirm: true });
  };

  return (
    <Common.CardLayout
      titleText="พิมพ์เอกสาร"
      closeable={props.closeable}
      toggleable={props.toggleable}
      onClose={props.onClose}
      loading={isLoading}
    >
      <Common.ModConfirm
        openModal={openModConfirm}
        className={"fixNotMiddleModal"}
        onDeny={() => setOpenModConfirm(false)}
        titleName={modConfirmTitle}
        onApprove={handleApproveConfirm}
        content={
          <Form>
            <Common.ErrorMessage error={modConfirmError} />
            <Form.Group>
              <Form.Field width={16}>
                <label>
                  ระบุเหตุผล
                  <label style={{ color: "red" }}>*</label>
                </label>
                <Common.ComboBox
                  ref={reasonRef as any}
                  options={reasonChoices}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        }
      />
      <Common.ModInfo
        className="fixNotMiddleModal"
        open={openModInfoTask}
        alertText={modInfoTaskText}
        titleName="กำลังเตรียมเอกสาร"
        btnText="ยกเลิก"
        titleColor="teal"
        buttonColor="red"
        textAlign={"left"}
        onApprove={handleCancelTask}
      />
      <Common.ModInfo
        className="fixNotMiddleModal"
        open={openModInfo}
        titleColor={modInfoTitleColor}
        titleName={modInfoTitle}
        alertText={
          typeof modInfoText !== "string" ? (
            <Common.ErrorMessage error={modInfoText} />
          ) : (
            modInfoText
          )
        }
        closeOnDimmerClick={true}
        onClose={() => setOpenModInfo(false)}
        onApprove={() => setOpenModInfo(false)}
      />
      <Modal
        open={openModEncounterList}
        closeOnDimmerClick={true}
        onClose={() => setOpenModEncounterList(false)}
        content={
          <Common.CardEncounterList
            patientId={props.patientId}
            controller={props.controller}
            onSelect={(selectedRow: any) => {
              (sbxEncounterRef as any).current.setText(selectedRow.number_date);
              (sbxEncounterRef as any).current.setId(selectedRow.id);
              handleSelectOption({ item: selectedRow });
              setOpenModEncounterList(false);
            }}
          />
        }
      />
      <Common.PatientIdentification patientInfo={props.patientInfo} />
      <Form>
        <Form.Group inline>
          <Form.Field>
            <label>Encounter Number :</label>
          </Form.Field>
          <Form.Field>
            <SearchBox
              ref={sbxEncounterRef}
              testid="sbxEncounter"
              linkIcon
              onIconClick={() => setOpenModEncounterList(true)}
              minCharacters={2}
              textField="number_date"
              onGetSearchOptions={handleSearchEncounter}
              onSelectOption={handleSelectOption}
              onClear={() => setEncounterId(props.encounterId)}
              defaultOptions={encounterList}
              defaultId={props.encounterId}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <br />
      <Grid>
        <Grid.Column width={4}>
          <PureReactTable
            style={styles.docTable}
            showPagination={false}
            testid="grdRequest"
            data={documentType}
            onSelect={handleSelectDocType}
            selectedRow={selectedRow}
            selectedClassName="blueSelectedRow"
            columns={[
              {
                Header: "ชื่อเอกสาร",
                accessor: "name",
              },
            ]}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment style={{ height: "500px" }}>
            <Common.FrameChrome file={file} visible />
          </Segment>
        </Grid.Column>
      </Grid>
      <br />
      <Form>
        <Form.Group inline className="rightAlign">
          <Form.Field width={3}>
            <Button
              color="blue"
              content="พิมพ์"
              fluid
              onClick={() => {
                getPrintAPI({
                  originalRow: selectedRow,
                  preview: false,
                  confirm: false,
                });
              }}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    </Common.CardLayout>
  );
});

const TASK_TRACKING_STATUS = {
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
  CANCELED: "CANCELED",
  IN_PROGRESS: "IN_PROGRESS"
};

const styles = {
  docTable: {
    height: "500px"
  }
};

CardPrintList.propTypes = {
  toggleable: PropTypes.bool,
  closeable: PropTypes.bool,
  patientInfo: PropTypes.object,
  onClose: PropTypes.func,
  patientData: PropTypes.object,
  controller: PropTypes.object,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  encounterNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  operatingOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CardPrintList.defaultProps = {
  toggleable: false,
  closeable: true,
  patientInfo: {},
  onClose: () => {},
  patientData: {},
  controller: {},
  patientId: null,
  encounterId: null,
  encounterNumber: null,
  operatingOrderId: null
};

export default React.memo(CardPrintList);
