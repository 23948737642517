/*eslint no-var: 0*/
import app from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/auth";
import "firebase/analytics";
import * as firebaseui from "firebaseui";
import firebaseConfig from "../../_manager/firebase-config";
import CONFIG from "config/config";

if (!app.apps.length && !CONFIG?.OFFLINE) {
  console.log("Firebase: !!!! src/react-lib/frameworks/Firebase.ts ");

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  console.log("Firebase: CONFIG: ", CONFIG);
  if (firebase_project === "") {
    console.error("Firebase: Not config FIREBASE_PROJECT in base.json");
  }

  console.log("Firebase: firebaseConfig: ", firebaseConfig);
  if (Array.isArray(firebaseConfig)) {
    let config = firebaseConfig.find((item: any) => item.projectId === firebase_project);
    console.log("Firebase: config: ", config);
    if (config) {
      app.initializeApp(config);
    } else {
      console.error("Firebase: Can't find suitable config, then use first");
      app.initializeApp(firebaseConfig[0]);
    }
  } else {
    // backward complatible
    app.initializeApp(firebaseConfig);
  }

  // app.firestore.setLogLevel('debug');
}

export { default as app } from "firebase/app";
export var db: app.firestore.Firestore | null = !CONFIG?.OFFLINE ? app.firestore() : null;
export var analytics: app.analytics.Analytics | null = !CONFIG?.OFFLINE ? app.analytics() : null;
export var functions: app.functions.Functions | null = !CONFIG?.OFFLINE ? app.functions() : null;
export var storage: app.storage.Storage | null = !CONFIG?.OFFLINE ? app.storage() : null;
export var auth: app.auth.Auth | null = !CONFIG?.OFFLINE ? app.auth() : null;
export const ui = !CONFIG?.OFFLINE ? new firebaseui.auth.AuthUI(app.auth()) : null;
